import React from "react";
import { useSelector } from "react-redux";
import CaretDownCircle from 'react-ionicons/lib/CaretDownCircle';
import CaretUpCircle from 'react-ionicons/lib/CaretUpCircle';
import SyncCircle from 'react-ionicons/lib/SyncCircle';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import styles from "./Card.module.sass";
import cn from "classnames";

const Card = ({
    id,
    icon,
    balanceData,
    walletType,
    openHistoryModal,
    openDepositModal,
    openWithdrawModal,
    openTransferModal,
}) => {
    const storedImage = localStorage.getItem('card_image');
    const storedFilterStyles = JSON.parse(localStorage.getItem('card_image_styles'));

    const isBalanceShown = useSelector(state => state.user.isBalanceShown);

    return (
        <div className={styles.wrap}>
            {storedFilterStyles[id] ? <div className={styles.card_bg_wrap}
            >
                <div
                    className={styles.card_bg}
                    style={{
                        backgroundImage: `url(${storedImage})`,
                        filter: `brightness(${storedFilterStyles[id].brightness}%) hue-rotate(${storedFilterStyles[id].hueRotate}deg`,
                        transform: `translate(${storedFilterStyles[id].translate.x || 0}, ${storedFilterStyles[id].translate.y || 0})`,
                    }}
                >
                </div>
            </div> : null}
            <div className={styles.row + " " + styles.card}>
                <div className={styles.col}>
                    <div
                        className={styles.icon}
                        onClick={() => { openHistoryModal(id) }}
                    >
                        <img loading="lazy" src={icon} alt="Currency" />
                        <b className={styles.info}>{id}</b>
                    </div>
                    <div className={styles.details}>
                        <div className={cn(styles.info, styles.usdt)}>{isBalanceShown ? balanceData.total_available : '********'} </div>
                        <div className={styles.text}>${isBalanceShown ? balanceData.total_available_usdt : '****'}</div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div>
                        {/* <p>{balanceData[x].address.slice(0,6)+" ❋❋❋ "+balanceData[x].address.slice(37,42)}</p> */}
                    </div>
                    <div className={styles.currency}>
                        {walletType !== 'margin' ? <div
                            className={styles.item}
                            onClick={() => { openDepositModal(id) }}
                        >
                            <CaretDownCircle
                                color={'#111'}
                                height="20px"
                                width="20px"
                            />
                        </div> : null}
                        {walletType !== 'margin' ? <span 
                            className={styles.item}
                            onClick={() => { openWithdrawModal(id) }}
                        >
                            <CaretUpCircle
                                color={'#111'}
                                height="20px"
                                width="20px"
                            />
                        </span> : null}
                        <span className={styles.item}>
                            <SyncCircle
                                color={'#111'}
                                height="20px"
                                width="20px"
                                onClick={() => { openTransferModal(id) }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;