const initialState = {
  list: [],
  pagination: {
    page: 1,
    totalCountPage: 0,
  },
  detail: null,
  isLoadingDetail: true,
  disclaimerText: null,
  categories: [],
  categoryContent: {},
  selectedCategory: null,
};

export default function ContentReducer(state = initialState, action) {
  const data = action.payload;

  switch (action.type) {
    case "LOAD_CONTENT_LIST":
      return {
        ...state,
        list: data.list,
        pagination: data.pagination,
      };
    case "START_LOAD_CONTENT_DETAIL":
      return {
        ...state,
        isLoadingDetail: true,
      };
    case "LOAD_CONTENT_DETAIL":
      return {
        ...state,
        detail: data,
      };
    case "SET_IS_CONTENT_DETAIL_LOADING":
      return {
        ...state,
        isLoadingDetail: data,
      };
    case "LOAD_CONTENT_DISCLAIMER_TEXT":
      return {
        ...state,
        disclaimerText: data,
      };
    case "LOAD_CONTENT_CATEGORIES":
      return {
        ...state,
        categories: data,
      };
    case "LOAD_CATEGORY_CONTENT":
      return {
        ...state,
        categoryContent: {
          ...state.categoryContent,
          [action.payload.category]: action.payload.data || {
            pagination: {
              page: 1,
              totalCountPage: 0
            },
            list: []
          }
        }
      };
    case "SET_SELECTED_CATEGORY": {
      return { ...state, selectedCategory: data };
    }
    default:
      return state;
  }
}
