import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import OutsideClickHandler from "react-outside-click-handler";

import cn from "classnames";
import styles from "./User.module.sass";

import Icon from "../../../components/Icon";
import Theme from "../../../components/Theme";

import axios from "../../../utils/Api";
import session_manager from '../../../utils/SessionManager';
import clevertap from "clevertap-web-sdk";
import LazyLoad from "react-lazyload";

const page = 'header.user';

/**
 *
 * @param className
 * @returns {JSX.Element}
 * @constructor
*/
const User = ({ className }) => {
    const [visible, setVisible] = useState(false);
    const history = useHistory();

    const { t } = useTranslation();

    const accountInfo = useSelector(state => state.user.account_info);

    const items = [
        {
            title: t(`${page}.items.trading.title`),
            content: t(`${page}.items.trading.content`),
            icon: "coin",
            url: "/trading",
        },
        {
            title: t(`${page}.items.wallet.title`),
            content: t(`${page}.items.wallet.content`),
            icon: "wallet",
            url: "/wallet-overview",
        },
        {
            title: t(`${page}.items.profile.title`),
            content: t(`${page}.items.profile.content`),
            icon: "user",
            url: "/profile-info",
        },
        {
            title: t(`${page}.items.verification.title`),
            content: t(`${page}.items.verification.content`),
            icon: "lock",
            url: "/verification",
        },
        {
            title: t(`${page}.items.referrals.title`),
            content: t(`${page}.items.referrals.content`),
            icon: "share",
            url: "/referrals",
        },
        {
            title: t(`${page}.items.twoFa.title`),
            content: t(`${page}.items.twoFa.content`),
            icon: "lock",
            url: "/2fa",
        },
        {
            title: t(`${page}.items.launchpad.title`),
            content: t(`${page}.items.launchpad.content`),
            icon: "lightning",
            url: "/launchpad",
        },
        // {
        //     title: t(`${page}.items.voting.title`),
        //     content: t(`${page}.items.voting.content`),
        //     icon: "star",
        //     url: "/voting",
        // },
        {
            title: t(`${page}.items.fees.title`),
            content: t(`${page}.items.fees.content`),
            icon: "coin",
            url: "/fees",
        },
        // {
        //     title: "Settings",
        //     icon: "cog",
        //     content: "View additional settings",
        //     url: "/profile-info",
        //     // url: "/api-keys",
        // },
        // {
        //     title: "Sessions & login history",
        //     icon: "laptop",
        //     content: "View active sessions and login history",
        //     url: "/sessions-and-login-history",
        // },
        {
            title: t(`${page}.items.darkMode.title`),
            content: t(`${page}.items.darkMode.content`),
            icon: "theme-dark",
            darkMode: true
        },
        {
            title: t(`${page}.items.logout.title`),
            icon: "exit",
        },
    ];

    const logOut = async () => {
        // Signout clicked
        clevertap.event.push("logout_from_other_devices_clicked", {
            userId: accountInfo?.profile?.id
        })

        await axios.post('/api/user/logout');

        session_manager.removeToken();

        history.push('/');
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(className, styles.user, { [styles.active]: visible })}>
                <button onClick={() => setVisible(!visible)}>
                    <LazyLoad>
                     <img loading="lazy" src="/images/content/avatar-user.png" alt="Avatar" />
                    </LazyLoad>
                </button>
                <div className={styles.body}>
                    <div className={styles.menu}>
                        {items.map((x, index) =>
                            x.url ? (
                                <Link
                                    className={styles.item}
                                    to={x.url}
                                    onClick={() => setVisible(!visible)}
                                    key={index}
                                >
                                    <div className={styles.icon}>
                                        <Icon name={x.icon} size="20" />
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.title}>{x.title}</div>
                                        <div className={styles.content}>{x.content}</div>
                                    </div>
                                </Link>
                            ) : (
                                (x.darkMode) ? (
                                    <div className={styles.item} key={index}>
                                        <div className={styles.icon}>
                                            <Icon name={x.icon} size="20" />
                                        </div>
                                        <div className={styles.details}>
                                            <div className={styles.line}>
                                                <div className={styles.title}>{x.title}</div>
                                                <Theme className={styles.theme} small />
                                            </div>
                                            <div className={styles.content}>{x.content}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.item} key={index} onClick={logOut}>
                                        <div className={styles.icon}>
                                            <Icon name={x.icon} size="20" />
                                        </div>
                                        <div className={styles.details}>
                                            <div className={styles.line}>
                                                <div className={styles.title}>{x.title}</div>
                                            </div>
                                            <div className={styles.content}>{x.content}</div>
                                        </div>
                                    </div>
                                )
                            )
                        )}
                    </div>
                </div>
            </div>
        </OutsideClickHandler >
    );
};

export default User;
