import { combineReducers } from "redux";

import config from "./ConfigReducer";
import graph from "./GraphReducer";
import ordersBook from "./OrdersBookReducer";
import quickExchange from "./QuickExchangeReducer"
import sockets from "./SocketsReducer";
import ticker from "./TickerReducer";
import trades from "./TradesReducer";
import user from "./UserReducer";
import account from "./AccountReducer";
import wallet from "./WalletReducer";
import voting from "./VotingReducer";
import priceAlerts from "./PriceAlerts";
import content from "./ContentReducer";
import leaderboard from "./LeaderboardReducer";

/**
 * Combine reducers and export it
 */
export default combineReducers({
    config,
    graph,
    ordersBook,
    quickExchange,
    sockets,
    ticker,
    trades,
    user,
    account,
    wallet,
    voting,
    priceAlerts,
    content,
    leaderboard
});
