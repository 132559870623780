import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import styles from "./Wallet.module.sass";

import Icon from "../Icon";
// import Modal from "../Modal";
// import Withdraw from "../Withdraw";
// import Deposit from "../Deposit";
// import Transfer from "../Transfer";

import LimitsMessage from "../../components/Alert/messages/LimitsMessage/LimitsMessage";
import fetchVerification from "../../store/actions/User/FetchVerification";
import fetchUserAccountInfo from "../../store/actions/User/FetchUserAccountInfo";
import { setAlert } from "../../store/actions/Alert/setAlert";
import session_manager from "../../utils/SessionManager";

const page = 'wallet';

const Wallet = ({ className, children }) => {
    /**
     * @type {Dispatch<AnyAction>}
     */
    const dispatch = useDispatch();

    /**
     *
     */
    const { t } = useTranslation();

    // const { pathname } = useLocation();
    // const activeItem = navigation.find((x) => pathname.includes(x.url));

    /**
     * Get available balance from state
     */
    const availableBalance = useSelector(state => state.user.available_balance);
    const isBalanceShown = useSelector(state => state.user.isBalanceShown);
    const verificationData = useSelector(state => state.user.verification_data);
    const accountInfo = useSelector(state => state.user.account_info);
    // const [visibleWithdraw, setVisibleWithdraw] = useState(false);
    // const [visibleTransfer, setVisibleTransfer] = useState(false);
    // const [visibleDeposit, setVisibleDeposit] = useState(false);
    // const [visibleMenu, setVisibleMenu] = useState(false);

    const blockIsActive = useSelector(state => state.config.block_is_active);

    const navigation = useMemo(() => {
        /**
         * @type {[]}
         * 
         * title, color, url, type (wallet), icon
         */
        const arr = [
            {
                title: t(`${page}.navigation.0.title`, { balance: `(${availableBalance?.spot.usdt}$)` }),
                color: "#302e2e",
                url: "/wallets",
                type: "spot"
            },
        ];

        if (blockIsActive.margin_active) {
            arr.push({
                title: t(`${page}.navigation.7.title`, { balance: `(${availableBalance?.margin.usdt}$)` }),
                color: "#9757D7",
                url: "/wallets/margin",
                type: "margin"
            });
        }

        arr.push({
            title: t(`${page}.navigation.2.title`, { balance: `(${availableBalance?.funding.usdt}$)` }),
            color: "#FFD166",
            url: "/wallets/funding",
            separator: true,
            type: "funding"
        });

        return arr;
    }, [blockIsActive, t, availableBalance])

    /**
     * Init store data
     */
    useEffect(() => {
        dispatch(fetchVerification());

        if (session_manager.isLoggedIn()) {
            dispatch(fetchUserAccountInfo());
        }
    }, [dispatch]);

    /**
     *
     */
    useEffect(() => {
        if (accountInfo) {
            dispatch(setAlert(false));

            if (accountInfo.profile.is_suspended) {
                dispatch(setAlert(t('alert.accountIsSuspended')));

                return;
            }
        }

        if (verificationData) {
            if (verificationData.verification.status === 2) {
                dispatch(setAlert(false));
            } else {
                if (verificationData.verification.status === 10) {
                    dispatch(setAlert(t('alert.waitApprovalMessage')));
                } else {
                    dispatch(setAlert(<LimitsMessage />));
                }
            }
        }

        return () => dispatch(setAlert(false));
    }, [verificationData, accountInfo]);

    /**
     * Render HTML
     */
    return (
        <>
            <div className={cn(className, styles.wallet)}>
                <div className={styles.sidebar}>
                    <div className={cn(styles.group
                        // , { [styles.active]: visibleMenu }
                    )}>
                        {/* <div
                            className={styles.top}
                            onClick={() => setVisibleMenu(!visibleMenu)}
                        >
                            <div className={styles.bg} style={{ backgroundColor: activeItem.color }}></div>
                            {activeItem.title}
                        </div> */}
                        <div className={styles.menu}>
                            {navigation.map((item, index) =>
                                // item.url ? (
                                <NavLink
                                    className={cn(styles.item, {
                                        [styles.separator]: item.separator,
                                    })}
                                    activeClassName={styles.active}
                                    to={item.url}
                                    key={index}
                                    exact
                                >
                                    {item.color && (
                                        <div
                                            className={styles.bg}
                                            style={{ backgroundColor: item.color }}
                                        ></div>
                                    )}
                                    {item.icon && <Icon name={item.icon} size="20" />}
                                    {item.title}
                                </NavLink>
                                // ) : (
                                //     <button
                                //         className={cn(styles.item, {
                                //             [styles.separator]: item.separator,
                                //         })}
                                //         key={index}
                                //         onClick={() => setVisibleTransfer(!visibleTransfer)}
                                //     >
                                //         {item.icon && <Icon name={item.icon} size="20" />}
                                //         {item.title}
                                //     </button>
                                // )
                            )}
                        </div>
                    </div>
                    {/* <div className={styles.btns}>
                        <button
                            className={cn("button button-small", styles.button)}
                            onClick={() => setVisibleDeposit(!visibleDeposit)}
                        >
                            Deposit
                        </button>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setVisibleWithdraw(!visibleWithdraw)}
                        >
                            Withdraw
                        </button>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setVisibleTransfer(!visibleTransfer)}
                        >
                            Transfer
                        </button>
                    </div> */}
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.panel}>
                        <div className={styles.info}>{t(`${page}.totalAvailable`)}</div>
                        <div className={styles.currency}>
                            <div className={styles.number}>{isBalanceShown ? availableBalance.total.btc : '**********'}</div>
                            <div className={cn("category-green", styles.category)}>BTC</div>
                        </div>
                        <div className={styles.price}>${isBalanceShown ? availableBalance.total.usdt : '******'}</div>
                    </div>
                    <div className={styles.menuTop}>
                        {navigation.map((item, index) =>
                            <NavLink
                                className={styles.item}
                                activeClassName={styles.active}
                                to={item.url}
                                key={index}
                                exact
                            >
                                <div className={styles.title}>
                                    {item.color && (
                                        <div
                                            className={styles.bg}
                                            style={{ backgroundColor: item.color }}
                                        ></div>
                                    )}
                                    {item.icon && <Icon name={item.icon} size="20" />}
                                    {item.title}
                                </div>
                                <span className={styles.balance}>${availableBalance[item.type].usdt}</span>
                            </NavLink>
                        )}
                    </div>
                    {children}
                </div>
            </div>
            {/* <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw
                    id='USDT'
                    walletType={walletType}
                    onClose={() => {
                        setVisibleWithdraw(false);
                    }}
                />
            </Modal>

            <Modal
                visible={visibleDeposit}
                onClose={() => setVisibleDeposit(false)}
            >
                <Deposit id='USDT' />
            </Modal> */}

            {/*<Modal*/}
            {/*    visible={visibleTransfer}*/}
            {/*    onClose={() => setVisibleTransfer(false)}*/}
            {/*>*/}
            {/*    <Transfer*/}
            {/*        ticker=""*/}
            {/*        walletType=""*/}
            {/*        onClose={() => setVisibleTransfer(false)}*/}
            {/*    />*/}
            {/*</Modal>*/}
        </>
    );
};

export default Wallet;
