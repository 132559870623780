import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CaretDownCircle from 'react-ionicons/lib/CaretDownCircle';
import CaretUpCircle from 'react-ionicons/lib/CaretUpCircle';
import SyncCircle from 'react-ionicons/lib/SyncCircle';
import TrendingUpOutline from 'react-ionicons/lib/TrendingUpOutline';
import { useTranslation } from "react-i18next";

import styles from "./List.module.sass";
import cn from "classnames";

const List = ({
    id,
    icon,
    balanceData,
    walletType,
    disabledButtons,
    openHistoryModal,
    openDepositModal,
    openWithdrawModal,
    openTransferModal,
}) => {
    /**
     *
     */
    const { t } = useTranslation();

    /**
     *
     * @type {boolean|*}
     */
    const isBalanceShown = useSelector(state => state.user.isBalanceShown);

    /**
     * Render HTML
     */
    return (
        <div className={styles.row} >
            <div className={cn(styles.col)}>
                <div
                    className={cn(styles.flex)}
                    onClick={() => { openHistoryModal(id) }}>
                    <div className={styles.icon}>
                        <img loading="lazy" src={icon} alt={id} />
                    </div>
                    <div className={styles.details}>
                        <span className={styles.subtitle}>{id}</span>
                    </div>
                </div>
            </div>
            <div className={styles.col}>
                {isBalanceShown ? balanceData.total_balance : '********'}
                <p className={styles.value}> ${isBalanceShown ? balanceData.total_balance_usdt : '****'}</p>
            </div>
            <div className={styles.col}>
                {isBalanceShown ? balanceData.total_available : '********'}
                <p className={styles.value}> ${isBalanceShown ? balanceData.total_available_usdt : '****'}</p>
            </div>
            <div className={styles.col}>
                {isBalanceShown ? balanceData.frozen : '********'}
                <p className={styles.value}> ${isBalanceShown ? balanceData.frozen_usdt : '****'}</p>
            </div>
            <div className={styles.col}>
                <div className={styles.currency}>
                    {id !== 'USDT' && <Link
                        to={`/trading/${id}USDT${walletType === 'margin' ? '?from=futures' : ''}`}
                        className={cn(styles.item, styles.main)}
                    >
                        <TrendingUpOutline
                            color={'#fff'}
                            height="18px"
                            width="18px"
                            cssClasses={'ml-1'}
                        />
                        <p className="px-3">{t(`wallet.btns.trade`)}</p>
                    </Link>}
                    {walletType !== 'margin' ? <button
                        // to={`/deposit/crypto/${id}`}
                        type="button"
                        disabled={disabledButtons.deposit}
                        className={cn(styles.item, styles.main)}
                        onClick={() => { openDepositModal(id) }}
                    >
                        <CaretDownCircle
                            color={'#fff'}
                            height="20px"
                            width="20px"
                        />
                        <p className="px-3">{t(`wallet.btns.deposit`)}</p>
                    </button> : null}
                    {walletType !== 'margin' ? <button
                        className={styles.item}
                        type="button"
                        disabled={disabledButtons.withdraw}
                        onClick={() => openWithdrawModal(id)}
                    >
                        <CaretUpCircle
                            color={'#fff'}
                            height="20px"
                            width="20px"
                        />
                        <p className="px-3">{t(`wallet.btns.withdraw`)} </p>
                    </button> : null}
                    <span className={styles.item} onClick={() => { openTransferModal(id) }}>
                        <SyncCircle
                            color={'#fff'}
                            height="18px"
                            width="18px"
                        />
                        <p className="px-3">{t(`wallet.btns.transfer`)} </p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default List;