/* eslint-disable import/no-anonymous-default-export */
const from_int = 100000000;
const long_signs_count = 8;

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const user_orders_statuses = {
    0: "processing",
    1: "public",
    2: "filled",
    3: "cancelled",
    6: "creating",
    '-1': 'partially filled'
};

const marginStatuses = {
    47: `Filled`,
    25: `Closed`,
    30: `Closed`,
    50: `Margin Call`,
    15: `Margin Call`,
    'cancelled': `Cancelled`,
    8: 'Open',
};

function _toFixed(num, fixed) {
    if (!num) {
        return;
    }

    const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);

    return num.toString().match(re)[0];
}

function compareNext(a, comparer, count, length) {
    if (count === length) {
        return count;
    }

    if (a > 1 * comparer) {
        count++;
        comparer = `9${comparer}`;

        return compareNext(a, comparer, count, length);
    }

    return count;
}

function convertFromInt(_num) {
    let num = _num / from_int;

    if (Math.abs(num) < 1.0) {
        let e = parseInt(num.toString().split('e-')[1]);
        if (e) {
            num *= Math.pow(10, e - 1);
            num = '0.' + (new Array(e)).join('0') + num.toString().substring(2);
        }
    } else {
        let e = parseInt(num.toString().split("+")[1]);

        if (e > 20) {
            e -= 20;
            num /= Math.pow(10, e);
            num += new Array(e + 1).join("0");
        }
    }

    return num;
}

function fullMonthTime(_timestamp) {
    const a = new Date(_timestamp * 1000);
    const month = months[a.getMonth()];
    const date = a.getDate() > 9 ? a.getDate() : `0${a.getDate()}`;

    return `${month} ${date}`;
    // return time;
}

function fullTimeAndDate(_timestamp) {
    const date = timeDD_MM_YY(_timestamp);
    const time = timeHH_MM_SS(_timestamp);

    return `${date} ${time}`;
}

export function timeConvert(_timestamp) {
    const a = new Date(_timestamp * 1000);
    const month = months[a.getMonth()].slice(0, 3);
    const date = a.getDate() > 9 ? a.getDate() : `0${a.getDate()}`;
    const hour = a.getHours() > 9 ? a.getHours() : `0${a.getHours()}`;
    const min = a.getMinutes() > 9 ? a.getMinutes() : `0${a.getMinutes()}`;

    return `${date} ${month} ${hour}:${min}`;
}

function timeConvertFromStandartFormat(_timestamp) {
    const a = new Date(_timestamp);
    const month = months[a.getMonth()].slice(0, 3);
    const date = a.getDate() > 9 ? a.getDate() : `0${a.getDate()}`;
    const hour = a.getHours() > 9 ? a.getHours() : `0${a.getHours()}`;
    const min = a.getMinutes() > 9 ? a.getMinutes() : `0${a.getMinutes()}`;

    return `${date} ${month} ${hour}:${min}`;
}

function timeDD_MM_YY(_timestamp) {
    const a = new Date(_timestamp * 1000);
    const year = a.getFullYear() - 2000;
    const month = a.getMonth() + 1 > 9 ? a.getMonth() + 1 : `0${a.getMonth() + 1}`;
    const date = a.getDate() > 9 ? a.getDate() : `0${a.getDate()}`;

    return `${date}.${month}.${year}`;
}

function timeHH_MM_SS(_timestamp) {
    const a = new Date(_timestamp * 1000);

    const hour = a.getHours() > 9 ? a.getHours() : `0${a.getHours()}`;
    const min = a.getMinutes() > 9 ? a.getMinutes() : `0${a.getMinutes()}`;
    const sec = a.getSeconds() > 9 ? a.getSeconds() : `0${a.getSeconds()}`;

    return `${hour}:${min}:${sec}`;
}

function isInteger(num) {
    return parseInt(num * 1) === num;
}

export function formatNum(inp, forceLong = false) {
    // console.log('formatNum');
    inp = +inp;
    //console.log('formatNum', inp, typeof inp);

    if (inp === 0) {
        return '';
    }

    let inp_to_trim;

    if (forceLong || inp < 1.1) {
        inp_to_trim = inp.toFixed(8);
    } else if (inp < 1.01) {
        inp_to_trim = inp.toFixed(7);
    } else if (inp < 1.1) {
        inp_to_trim = inp.toFixed(6);
    } else {
        inp_to_trim = inp.toFixed(2);
    }

    return inp_to_trim.toString().replace(/(\.)?0{2,}$/, '');
}

export default {
    countAfterDot(num, length, is_int) {
        if (is_int) {
            num /= from_int;
        }

        return compareNext(num, "9.9", 1, length);
    },
    filterPairSocketData(pair) {
        pair.diff = pair.rate - pair.high;

        return pair;
    },
    fitNumLength(num, length, is_int) {
        return length - this.countAfterDot(num, length, is_int);
    },
    fitPercentLength(num) {
        if (num > 9999) {
            return 0;
        }

        if (num > 999) {
            return 1;
        }

        return 2;
    },
    fixedFromInt(num, count) {
        if (!num) {
            return (0).toFixed(count);
        }

        return _toFixed(convertFromInt(num), count);
    },
    formatBookPrice(num) {
        let after_dot;

        // if (num / from_int > 100) {
        //     after_dot = 4;
        // } else if (num / from_int > 10) {
        //     after_dot = 5;
        // } else if (num / from_int > 1) {
        //     after_dot = 6;
        // } else {
        //     after_dot = 8;
        // }

        after_dot = 8;

        return (1 * _toFixed(convertFromInt(num), after_dot)).toFixed(after_dot);
    },
    formatNumCurrency(num, decimal = 2, zeroCut = 2, alreadyFloat = false, hundredsDelimiter = true) {
        const fixedNum = alreadyFloat ? String(num) : this.fixedFromInt(+num, decimal);

        const [integerPart, decimalPart] = fixedNum.split('.');

        let integerValue = hundredsDelimiter ? integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : integerPart;

        const floatingValue = decimalPart ? decimalPart.replace(/0+$/, '').slice(0, decimal) : '';

        const zeroPadding = '0'.repeat(Math.max(zeroCut - floatingValue.length, 0));

        return floatingValue ? `${integerValue}.${floatingValue}${zeroPadding}` : (zeroCut ? `${integerValue}.${zeroPadding}` : integerValue);
    },
    formatNumCurrency_New(num, decimal = 2, zeroCut = 2, alreadyFloat = false, hundredsDelimiter = true) {
        const fixedNum = alreadyFloat ? String(num) : this.fixedFromInt(+num, decimal);
        const [integerPart, decimalPart] = fixedNum.split('.');

        /**
         * ### For numbers less than 1 but greater than 0
         */
        if (Number(integerPart) === 0) {
            if (!decimalPart || /^0+$/.test(decimalPart)) {
                return '0.' + '0'.repeat(zeroCut);
            } else {
                return Number(`0.${decimalPart.replace(/0+$/, '').padEnd(zeroCut, '0')}`).toFixed(decimal);
            }
        }

        /**
         * ### For integers greater than 1 and large numbers
         */
        const integerValue = hundredsDelimiter ? integerPart.replace(/(?<!\.\d*)\B(?=(\d{3})+(?!\d))/g, ',') : integerPart;

        let decimalValue;

        if(decimalPart){
            decimalValue = (decimalPart || 0).slice(0, decimal).replace(/0+$/, '');

            if(decimalValue.length < zeroCut){
                decimalValue = decimalValue + '0'.repeat(zeroCut - decimalValue.length);
            }
        }else{
            decimalValue = '0'.repeat(zeroCut);
        }

        const result = `${integerValue}.${decimalValue}`;

        return result.length > 0 ? result : '0';
    },

    isPairType(pair) {
        for (const prop in pair) {
            if (typeof pair[prop] === "undefined") {
                return false;
            }
        }

        return true;
    },
    makeSecondCurrList(data) {
        const secondCurrencies = {};
        const count = data.length;

        if (count) {
            data.forEach(elem => {
                if (elem.rate) secondCurrencies[elem.second.iso3] = elem.second.iso3;
            });
        }

        return secondCurrencies; // ['USD', 'BTC']
    },
    numberAddCommas(num) {
        if (num === 0) {
            return "0";
        }

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    prepareUsersOrders(orders, pairs, type = "exchange") {
        //console.log('prepareUsersOrders', orders, pairs, type);
        const _userOrders = {};
        const count = orders.length;

        for (let i = 0; i < count; i++) {
            if (type === "margin") {
                orders[i].type_trade = orders[i].order_type;
                orders[i].type = orders[i].side;
                orders[i].currency_pair_id = orders[i].pair_id;
            }

            if (!(orders[i].currency_pair_id in pairs)) {
                continue;
            }

            let tradeType = "limit";

            if (orders[i].type_trade === 1) {
                tradeType = "market";
            } else if (orders[i].type_trade === 2) {
                tradeType = "stoplimit";
            } else if (orders[i].type_trade === 3) {
                tradeType = "quick_market";
            }

            const _avg = orders[i].price_done === null ? 0 : (orders[i].price_done / orders[i].volume_done) * from_int;

            try {
                const order = {
                    symbol: `${pairs[orders[i].currency_pair_id].main}/${pairs[orders[i].currency_pair_id].second}`,
                    second_pair: `${pairs[orders[i].currency_pair_id].second}`,
                    main_name: pairs[orders[i].currency_pair_id].main,
                    pair_id: orders[i].currency_pair_id,
                    trade_type: tradeType,
                    type: orders[i].type ? "sell" : "buy",
                    fee: {
                        commission: orders[i].commission,
                        currency: orders[i].type
                            ? pairs[orders[i].currency_pair_id].second
                            : pairs[orders[i].currency_pair_id].main,
                    },
                    payed_fee: {
                        main_currency: orders[i].payed_fee_main_currency ? this.fixedFromInt(
                            orders[i].payed_fee_main_currency,
                            this.fitNumLength(orders[i].payed_fee_main_currency, 8, true)
                        ) : null,
                        main_currency_usdt: orders[i].payed_fee_main_currency_usdt ? this.fixedFromInt(
                            orders[i].payed_fee_main_currency_usdt,
                            this.fitNumLength(orders[i].payed_fee_main_currency_usdt, 8, true)
                        ) : null,
                        second_currency: orders[i].payed_fee_second_currency ? this.fixedFromInt(
                            orders[i].payed_fee_second_currency,
                            this.fitNumLength(orders[i].payed_fee_second_currency, 8, true)
                        ) : null,
                        second_currency_usdt: orders[i].payed_fee_second_currency_usdt ? this.fixedFromInt(
                            orders[i].payed_fee_second_currency_usdt,
                            this.fitNumLength(orders[i].payed_fee_second_currency_usdt, 8, true)
                        ) : null,
                    },
                    status: (orders[i].status === 3 && orders[i].volume_done !== 0) ? 'partially filled' : user_orders_statuses[orders[i].status],
                    marker: !orders[i].type,
                    id: orders[i].id
                };

                if (type === "exchange") {
                    _userOrders[orders[i].id] = {
                        ...order,
                        id: orders[i].id,
                        avg_price: {
                            short: this.fixedFromInt(_avg, this.fitNumLength(_avg, 5, true)),
                            long: this.fixedFromInt(_avg, 8)
                        },
                        summ: {
                            amount: this.fixedFromInt(
                                orders[i].volume,
                                this.fitNumLength(orders[i].volume, 8, true)
                            ),
                            amount_long: this.fixedFromInt(orders[i].volume, 8),
                            currency: pairs[orders[i].currency_pair_id].main
                        },
                        volume_done: {
                            amount: this.fixedFromInt(
                                orders[i].volume_done,
                                this.fitNumLength(orders[i].volume_done, 8, true)
                            ),
                            amount_long: this.fixedFromInt(orders[i].volume_done, 8),
                            currency: pairs[orders[i].currency_pair_id].main
                        },
                        volume_left: orders[i].volume - orders[i].volume_done,

                        price: {
                            short: this.fixedFromInt(
                                orders[i].rate,
                                this.fitNumLength(orders[i].rate, 5, true)
                            ),
                            // long: this.formatNumCurrency(orders[i].rate, 8)
                            long: this.fixedFromInt(orders[i].rate, 9)
                        },

                        rate: {
                            short: this.fixedFromInt(
                                orders[i].rate_avg_done,
                                this.fitNumLength(orders[i].rate_avg_done, 5, true)
                            ),
                            long: this.formatNumCurrency(orders[i].rate_avg_done, 8)
                        },

                        // total: this.formatNumCurrency(orders[i].price),
                        total: convertFromInt(orders[i].price),

                        stopLimitPrice: {
                            short: orders[i].stop_rate
                                ? this.fixedFromInt(
                                    orders[i].stop_rate,
                                    this.fitNumLength(orders[i].stop_rate, 5, true)
                                )
                                : null,
                            long: orders[i].stop_rate
                                ? this.fixedFromInt(orders[i].stop_rate, 8)
                                : null
                        },
                        price_done: {
                            short: this.fixedFromInt(
                                orders[i].price_done,
                                this.fitNumLength(orders[i].price_done, orders[i].currency_second_iso3 === 'USDT' ? 3 : 5, true)
                            ),
                            long: this.fixedFromInt(orders[i].price_done, 8)
                        },

                        // Formated value
                        price_done_f: orders[i].price_done_f,
                        price_f: orders[i].price_f,
                        rate_avg_done_f: orders[i].rate_avg_done_f,
                        rate_f: orders[i].rate_f,
                        volume_done_f: orders[i].volume_done_f,
                        volume_f: orders[i].volume_f,

                        time_create: {
                            timestamp: orders[i].time_create,
                            converted: timeConvert(orders[i].time_create)
                        },
                        time_done: {
                            timestamp: orders[i].time_done,
                            converted: timeConvert(orders[i].time_done)
                        },
                        filled_percent: orders[i].filled_percent,
                        total_done: this.fixedFromInt(_avg, 8) * this.fixedFromInt(orders[i].volume_done, 8)
                    };
                } else if (type === "margin") {
                    const status = +orders[i].status === 30 && orders[i].firstOrders.filled < (10 ** 8 - 1) / (10 ** 8) ? 'cancelled' : +orders[i].status;

                    // const statusAddText =
                    //     // Check if the status is not 'cancelled'
                    //     status !== 'cancelled' ? (
                    //         // If status is 47, calculate and return the percentage of filled first orders
                    //         +orders[i].status === 47
                    //             ? (orders[i].firstOrders.filled * 100).toFixed(0)
                    //             // If status is 30 or 25, calculate and return the percentage of filled second orders
                    //             : +orders[i].status === 30 || +orders[i].status === 25
                    //                 ? (orders[i].secondOrders.filled * 100).toFixed(0)
                    //                 // If none of the above conditions are met, return an empty string
                    //                 : ''
                    //         // If status is 'cancelled', return an empty string
                    //     ) : '';

                    _userOrders[orders[i].id] = {
                        ...order,
                        status_style: status === 'cancelled' || status === 30 || status === 15 ? 'gray' : '',
                        status_text: `${marginStatuses[status] || '-'}`,
                        id: orders[i].id,
                        amount: _toFixed(orders[i].start_value.amount, 9), //.replace(/0+$/, ''),
                        base_price: orders[i].start_value.price,
                        liq_price: _toFixed(orders[i].liq_price, this.fitNumLength(orders[i].liq_price, 5, false)) || 0,
                        close_price: _toFixed(orders[i].now_value.price, this.fitNumLength(orders[i].now_value.price, 5, false)) || 0,
                        pl: _toFixed(orders[i].pl_volume, this.fitNumLength(orders[i].pl_volume, 5, false)),
                        pl_percent: orders[i].pl_degrade,
                        funding_cost: orders[i].funding_cost,
                        status: orders[i].status,
                        limit_rate: orders[i].limit_rate && this.fixedFromInt(orders[i].limit_rate, this.fitNumLength(orders[i].limit_rate, 8, true)),
                        time_create: {
                            timestamp: orders[i].created_at,
                            converted: timeConvertFromStandartFormat(orders[i].created_at)
                        },
                        total: orders[i].now_value.result && _toFixed(orders[i].now_value.result, this.fitNumLength(orders[i].now_value.result, 5, false)),
                    };
                }
            } catch (e) {
                // console.log(e);
            }
        }

        return _userOrders;
    },

    /**
     * @param _array
     * @param count
     * @returns {{total: number, orders: *[]}}
     */
    prepareBookOrders(_array, count) {
        const renderOrders = [];
        let cumulative = 0;

        for (let i = 0; i < count; i++) {
            if (_array[i]) {
                cumulative += _array[i].volume * 1;

                renderOrders.push({
                    count: _array[i].count,
                    volume: _array[i].volume,
                    volume_f: _array[i].volume_f,
                    sharp_volume: _array[i].volume,
                    cumulative_volume: cumulative,
                    rate: _array[i].rate,
                    rate_f: _array[i].rate_f,
                    price: _array[i].price,
                    price_f: _array[i].price_f,
                });
            }
        }

        for (let i = 0; i < count; i++) {
            if (renderOrders[i]) {
                renderOrders[i].cumul_width = (renderOrders[i].cumulative_volume / cumulative) * 100;
                renderOrders[i].own_width = (renderOrders[i].sharp_volume / cumulative) * 100;
                renderOrders[i].total_volume = (renderOrders[i].cumulative_volume / from_int).toFixed(8);
            }
        }

        return {
            orders: renderOrders,
            total: cumulative
        };
    },

    /**
     * @param _array
     * @param count
     * @param is_sell
     * @returns {*[]}
     */
    prepareBookGraphOrders(_array, count, is_sell) {
        const renderOrders = [];
        let cumulative = 0;
        if (!is_sell) {
            for (let i = 0; i < count; i++) {
                if (_array[i]) {
                    cumulative += _array[i].volume * 1;
                    renderOrders.push([
                        this.formatBookPrice(_array[i].rate) * 1,
                        null,
                        cumulative / from_int
                    ]);
                }
            }
        } else {
            cumulative = 0;
            for (let i = 0; i < count; i++) {
                if (_array[i]) {
                    cumulative += _array[i].volume * 1;
                    renderOrders.push([
                        this.formatBookPrice(_array[i].rate) * 1,
                        cumulative / from_int,
                        null
                    ]);
                }
            }
        }

        return renderOrders;
    },

    renderPreparePair(pair, rate, nice_name) {
        if (!pair) {
            return null;
        }

        // converter to usd
        let volumeDenominator = pair.rate * (pair.second.rate_usdt ? pair.second.rate_usdt : 0);

        if (pair.second.iso3.toString().toUpperCase() === "USDT") {
            volumeDenominator = pair.rate * 100000000;
        } else if (pair.main.iso3.toString().toUpperCase() === "USDT") {
            volumeDenominator = (1 / (pair.rate / 100000000)) * 100000000 * 100000000;
        } else if (volumeDenominator === 0 && pair.main.rate_usdt > 0) {
            volumeDenominator = pair.main.rate_usdt * 100000000;
        }

        const secondVolume = (pair.volume / 10 ** 8) * (pair.rate / 10 ** 8);
        const volumeWorld = pair.volume_world / 10 ** pair.main.decimal;
        const volumeSecondWorld = pair.volume_second_world / 10 ** pair.second.decimal;

        return {
            secondVolume: secondVolume.toFixed(4),
            id: pair.id,
            type_filter_main: pair.main.type_filter,
            decimal: pair.main.decimal,
            second_decimal: pair.second.decimal,
            nice_name,
            ticker: `${pair.main.iso3}${pair.second.iso3}`,
            pair_name: `${pair.main.iso3}/${pair.second.iso3}`,
            diff: this.fixedFromInt(pair.rate * (pair.percent / 100), 4),
            green: !pair.percent ? false : pair.percent >= 0,
            greenW: !pair.percentW ? false : pair.percentW >= 0,
            greenH: !pair.percentH ? false : pair.percentH >= 0,
            volume: (1 * pair.volume * volumeDenominator) / 10 ** 24,

            low: (
                1 * this.fixedFromInt(pair.low, this.fitNumLength(pair.low, 9, true))
            )
                .toFixed(8)
                .slice(0, 10),

            high: (
                1 * this.fixedFromInt(pair.high, this.fitNumLength(pair.high, 9, true))
            )
                .toFixed(8)
                .slice(0, 10), // in 24 hours

            last: (
                1 * this.fixedFromInt(pair.rate, this.fitNumLength(pair.rate, 9, true))
            )
                .toFixed(8)
                .slice(0, 10),

            last_f: pair.rate_f,

            last_to_usd: pair.main.rate_usdt
                ? this.fixedFromInt(
                    pair.main.rate_usdt,
                    this.fitNumLength(pair.main.rate_usdt, 9, true)
                )
                : 0,

            percent:
                !pair.percent
                    ? 0
                    : pair.percent.toFixed(this.fitPercentLength(pair.percent)),

            percentH:
                !pair.percentH
                    ? 0
                    : pair.percentH.toFixed(this.fitPercentLength(pair.percentH)),

            percentW:
                !pair.percentW
                    ? 0
                    : pair.percentW.toFixed(this.fitPercentLength(pair.percentW)),

            second_name: pair.second.iso3,
            second_id: pair.second.id,
            type_filter_second: pair.second.type_filter,
            main_name: pair.main.iso3,
            main_id: pair.main.id,
            volume_world: volumeWorld,
            volume_second_world: volumeSecondWorld,

            volumes: pair.volumes
                ? {
                    day: (Number(pair.volumes.d) * volumeDenominator) / 10 ** 24,
                    week: (Number(pair.volumes.w) * volumeDenominator) / 10 ** 24,
                    month: (Number(pair.volumes.m) * volumeDenominator) / 10 ** 24,
                    own_day: this.numberAddCommas(this.fixedFromInt(pair.volumes.d, 5)),
                    own_week: this.numberAddCommas(
                        this.fixedFromInt(pair.volumes.w, 5)
                    ),
                    own_month: this.numberAddCommas(
                        this.fixedFromInt(pair.volumes.m, 5)
                    )
                }
                : { day: 0, week: 0, month: 0 }
        };
    },

    // recent withdrawals and deposits
    prepareRecentCashFlows(orders, diagram) {
        const ready_orders = [];

        for (const order in orders) {
            ready_orders.push({
                step: orders[order].step,
                create_time: {
                    timestamp: orders[order].time_create,
                    in_words: fullMonthTime(orders[order].time_create),
                    full_time: fullTimeAndDate(orders[order].time_create)
                },
                time_close: orders[order].time_done
                    ? fullTimeAndDate(orders[order].time_done)
                    : "no close time",
                diagram: diagram
                    ? {
                        full: orders[order].max_confirmations,
                        complete: orders[order].confirmations,
                        show: orders[order].status !== 1
                    }
                    : {
                        show: false
                    },
                status: orders[order].status,
                confirmed:
                    orders[order].max_confirmations === orders[order].confirmations,
                addresses: {
                    comment: orders[order].comment,
                    address_explore: orders[order].explorer_address,
                    to_address: orders[order].to_address,
                    txid_explore:
                        orders[order].txid !== "waiting"
                            ? orders[order].explorer_txid
                            : null,
                    txid: orders[order].txid !== "waiting" ? orders[order].txid : null
                },
                id: orders[order].id,
                amount: {
                    amount: this.fixedFromInt(
                        orders[order].amount,
                        long_signs_count
                    ),
                    amount_sent: this.fixedFromInt(
                        orders[order].amount_send,
                        long_signs_count
                    ),
                    commission: this.fixedFromInt(
                        orders[order].commission,
                        long_signs_count
                    ),
                    currency: orders[order].iso3,
                    type_wallet: "exchange_wallet"
                },
                marker: orders[order].max_confirmations === orders[order].confirmations
            });
        }

        return ready_orders;
    },

    // trades
    renderPrepareTrades(orders) {
        orders.forEach(order => {
            order.saturation = order.volume / from_int;
            order.volume = this.fixedFromInt(order.volume, 10);
            order.rate = this.formatBookPrice(order.rate);
            order.isG = order.type === 0;
            order._type = order.type === 0 ? "Buy" : "Sell";
            // order.time_create_converted = timeHH_MM_SS(order.time_create);
            order.time_create_converted = timeConvert(order.time_create);
        });

        return orders;
    },

    prepareTransferOrders(orders) {
        const ready_orders = [];

        for (const order in orders) {
            let record = {
                id: orders[order].id,
                time: {
                    timestamp: orders[order].time_create,
                    full_time: (new Date(orders[order].time_create * 1000)).toLocaleString()
                },
                type: orders[order].type,
                status: 15,
                amount: this.fixedFromInt(orders[order].amount, 8),
                direction: orders[order].amount > 0 ? 'in' : 'out'
            };

            if (record.direction === 'in') {
                const to = orders[order].deposit_from_id.split(':');

                record.currency_id = to[1];
                record.wallet_type = to[2] || 'spot';
            } else {
                const from = orders[order].deposit_from_id.split(':');

                record.currency_id = from[1];
                record.wallet_type = from[2] || 'spot';
            }

            // Add new record
            ready_orders.push(record);
        }

        return ready_orders;
    },

    truncateDecimals(number, decimal) {
        // обрезаем знаки после запьятой
        if (number * 1 === 0 || !number) {
            return 0;
        } else if (isInteger(number * 1)) {
            return Number(number);
        }

        let num = (number * 1).toLocaleString("ru", {
            maximumSignificantDigits: 21
        }),
            result = num.split(","),
            val_after_dot = `${result[1]}00000000`.slice(0, decimal),
            RegEx = /\s/g,
            // RegEx убирает пробел так как функция  toLocaleString их добавляет
            firstRes = result[0].replace(RegEx, "");

        return `${firstRes}.${val_after_dot}`;
    },

    formatUSDT(number) {
        return this.formatNumCurrency_New(number, 0, 0, false)
    }
};