import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getContentDetail, setContentDetailIsLoading } from "../../store/actions/Content/GetContentDetail";
import Loader from "../../components/Loader";

const NotFound = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const isConfigsLoaded = useSelector(({ config }) => config.isConfigsLoaded);

  const news_path = useSelector(state => state.config.news_path);
  const history = useHistory();

  const params = useParams();

  /**
   * Get store data
   */
  const contentDetail = useSelector(state => state.content.detail);
  const isLoadingContentDetail = useSelector(state => state.content.isLoadingDetail);

  /**
   * Watch localization, load content
   */
  useEffect(() => {
    if (params.slug) {
      dispatch(getContentDetail(params.slug, i18n.language));
    } else {
      dispatch(setContentDetailIsLoading(false))
    }
  }, [i18n.language, params.slug]);

  const categoryHC = history.location.pathname.split('/')[1];
  const isCategoryHardCoded = (!params.category && ['help-center'].includes(categoryHC))

  useEffect(() => {
    const storedNewsPath = localStorage.getItem('news_path');

    if (isConfigsLoaded && news_path !== storedNewsPath) {
      localStorage.setItem('news_path', news_path);
    }

    function checkCurrentRoute() {
      if (!storedNewsPath || !params.slug || (!params.category && !['help-center'].includes(categoryHC))) return;

      if (!isLoadingContentDetail) {
        if (contentDetail) {
          history.replace(`/${storedNewsPath}/${params.category}/${params.slug}`);
        } else {
          if (categoryHC && isCategoryHardCoded) {
            history.replace(`/${categoryHC}`)
          } else {
            history.replace(`/${storedNewsPath}`)
          }
        }
      }
    }
    checkCurrentRoute();
  }, [isConfigsLoaded, news_path, contentDetail, isLoadingContentDetail])

  if (contentDetail || isLoadingContentDetail) {
    return <Loader />
  }

  return (
    <div className="d-flex justify-center items-center text-center my-24">
      <h1 className="text-2xl font-semibold">404 - Not Found</h1>
      <p className="text-lg">The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;