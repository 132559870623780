import {lazy} from "react";
import WalletOverview from "../screens/WalletOverview";

/**
 * ## Pages
 *
 * @type {[]}
 */
export const ProtectedRoutesList = [
    {
        path: "/withdraw/:currency_iso3",
        component: lazy(() => import("../components/Withdraw")),
    },
    /**
     * Wallets
     */
    {
        path: "/wallets",
        component: lazy(() => import("../screens/WalletOverview")),
    },
    {
        path: "/wallets/:walletType",
        component: lazy(() => import("../screens/WalletOverview")),
    },
    // {
    //     path: "/wallets/:walletType/:iso",
    //     component: lazy(() => import("../screens/WalletOverview")),
    // },
    {
        path: "/wallet-overview",
        component: lazy(() => import("../screens/WalletOverview")),
    },
    {
        path: "/wallet-overview/:iso",
        component: lazy(() => import("../screens/WalletOverview")),
    },
    {
        path: "/wallet-margin",
        component: lazy(() => import("../screens/WalletMargin")),
    },
    {
        path: "/fiat-and-spot",
        component: lazy(() => import("../screens/FiatAndSpot")),
    },
    {
        path: "/deposit/crypto/:iso",
        component: lazy(() => import("../screens/DepositCrypto")),
    },
    {
        path: "/subscription-history",
        component: lazy(() => import("../screens/LaunchpadSubscriptionHistory")),
    },
    {
        path: "/voting/user-center/:id",
        component: lazy(() => import("../screens/Voting/UserCenter")),
    },
    // {
    //     path: "/voting/user-center/voting-history",
    //     component: lazy(() => import("../screens/Voting/VotingHistory")),
    // },
    // {
    //     path: "/voting/user-center/win",
    //     component: lazy(() => import("../screens/Voting/WinningHistory")),
    // },
    // {
    //     path: "/deposit-fiat",
    //     component: lazy(() => import("../screens/DepositFiat")),
    // },

    /**
     * Profile
     */
    {
        path: "/profile-info",
        component: lazy(() => import("../screens/ProfileInfo")),
    },
    {
        path: "/vip-client",
        component: lazy(() => import("../screens/Vip")),
    },
    {
        path: "/vip-client/form",
        component: lazy(() => import("../screens/Vip/Form")),
    },
    {
        path: "/api-keys",
        component: lazy(() => import("../screens/ApiKeys"))
    },
    {
        path: "/sessions-and-login-history",
        component: lazy(() => import("../screens/SessionsAndLoginHistory"))
    },
    {
        path: "/address-management",
        component: lazy(() => import("../screens/AddressManagement")),
    },
    {
        path: "/2fa",
        component: lazy(() => import("../screens/TwoFa"))
    },
    {
        path: "/personal-pin",
        component: lazy(() => import("../screens/PersonalPin"))
    },
    {
        path: "/verification",
        component: lazy(() => import("../screens/Verification"))
    },
    {
        path: "/verification/:step",
        component: lazy(() => import("../screens/KYC"))
    },
    {
        path: "/verification/success",
        component: lazy(() => import("../screens/Verification/Result/Success"))
    },
    {
        path: "/verification/fail",
        component: lazy(() => import("../screens/Verification/Result/Fail"))
    },
    // {
    //     path: "/verification/steps/:id",
    //     component: lazy(() => import("../screens/Verification/Steps"))
    // },
    {
        path: "/change-password",
        component: lazy(() => import("../screens/ChangePassword"))
    },
    {
        path: "/change-phone-number",
        component: lazy(() => import("../screens/ChangePhoneNumber"))
    },
    // {
    //     path: "/link-confirmation/:base64String",
    //     component: lazy(() => import("../screens/LinkConfirmation"))
    // },
    {
        path: "/link-confirmation/:base64String",
        component: lazy(() => import("../screens/WithdrawalPincodeConfirmation"))
    },
    {
        path: "/2fa-link-confirmation-sent",
        component: lazy(() => import("../screens/TwoFaConfirmationLinkSent")),
    },
    // {
    //     path: "/activity",
    //     component: lazy(() => import("../screens/Activity")),
    // },
];
