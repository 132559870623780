import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Icon from "../../components/Icon";
import "./Header.css";

import styles from "./Header.module.sass";
import Image from "../../components/Image";
import Theme from "../../components/Theme";
import session_manager from "../../utils/SessionManager";
import User from "./User";
import DropdownHeader from "./Dropdown";
import Dropdown from "../../components/Dropdown";

import fetchUserAccountInfo from "../../store/actions/User/FetchUserAccountInfo";
import { setAlert } from "../../store/actions/Alert/setAlert";
import clevertap from "clevertap-web-sdk";

const page = 'header';

/**
 * Languages locales list
 *
 * @type {{}}
 */
const languagesList = {
    "en": "English",
    "tr": "Türkçe",
    "ar": "العربية",
    "th": "ไทย",
    "vi": "Tiếng Việt",
    "de": "Deutsch",
    "el": "Ελληνικά",
   
    // "es": "Español",
    "fa": "فارسی",
    "fr": "Français",
    // "hi": "हिन्दी",
    "id": "Bahasa Indonesia",
    "it": "Italiano",
    "lt": "Lietuvių",
    "ms": "Bahasa Melayu",
    "nl": "Nederlands",
    "pl": "Polski",
    "pt": "Português",
    "ru": "Русский",
    "sv": "Svenska",
   
    "tl": "Tagalog",
   
    "uk": "Українська",
   
    "zh_CN": "中文 (简体)"
};

/**
 *
 * @param headerWide
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({ headerWide }) => {
    /**
     *
     */
    const { t, i18n } = useTranslation();

    /**
     * Init dispatch store
     *
     * @type {Dispatch<AnyAction>}
     */
    const dispatch = useDispatch();

    /**
     *
     */
    const [visibleNav, setVisibleNav] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [download, showDownload] = useState(true);

    /**
     * Get state data
     */
    const accountInfo = useSelector(state => state.user.account_info);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const languagesFlip = Object.fromEntries(
        Object.entries(languagesList).map(([key, value]) => [value, key])
    );

    const { search, pathname } = useLocation();
    const token = new URLSearchParams(search).get("token");
    const webview = pathname.includes("webview");
    const sign = new URLSearchParams(search).get("sign");
    const isHomePage = pathname === '/';

    const navigation = [
        {
            title: t(`${page}.navigation.0.title`),
            url: "/trading",
            disabled: true,
        },
        {
            title: t(`${page}.navigation.2.title`),
            url: "/markets",
        },
        // {
        //     title: t(`${page}.navigation.6.title`),
        //     url: "/blog",
        // },
        {
            title: t(`${page}.navigation.3.title`),
            url: "/launchpad",
        },
        // {
        //     title: t(`${page}.navigation.5.title`),
        //     url: "/voting",
        // },
        {
            title: t(`${page}.navigation.4.title`),
            url: "/blog",
        },
        {
            title: t('header.navigation.6.title'),
            dropdown: [
                {
                    title: t('header.navigation.6.dropdown.0.title'),
                    icon: "user",
                    url: "/career",
                },
                {
                    title: t('header.navigation.6.dropdown.1.title'),
                    icon: "megaphone",
                    url: "/campaign/beta-testing-campaign",
                },
                {
                    title: t('header.navigation.6.dropdown.2.title'),
                    icon: "user",
                    url: "/leaderboard",
                },
            ],
        },
    ];

    /**
     * Auth buttons
     * @returns {JSX.Element}
     */
    const authButtons = () => {
        return (
            <div className={styles.btns}>
                <NavLink
                    style={{ pointerEvents: "none", color: "gray" }}
                    className={cn("button-stroke button-small", styles.button, styles.buttonAuth, isHomePage && styles.buttonDark)}
                    activeClassName={styles.active}
                    to="/login"
                    onClick={() => setVisibleNav(false)}
                >{t(`${page}.authButtons.login`)}</NavLink>
                <NavLink
                    style={{ pointerEvents: "none", color: "gray" }}
                    className={cn("button-small", styles.button, styles.buttonAuth)}
                    activeClassName={styles.active}
                    to="/registration"
                    onClick={() => setVisibleNav(false)}
                >{t(`${page}.authButtons.createAccount`)}</NavLink>
            </div>
        )
    };

    /**
     * Init store data
     */
    useEffect(() => {
        if (session_manager.isLoggedIn()) {
            dispatch(fetchUserAccountInfo());
        }
    }, [dispatch]);

    /**
     * Watch accountInfo and show alert
     */
    useEffect(() => {
        if (accountInfo) {
            dispatch(setAlert(false));

            if (accountInfo.profile.is_suspended) {
                dispatch(setAlert(t('alert.accountIsSuspended')));
            }
        }

        return () => dispatch(setAlert(false))
    }, [accountInfo])

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    /**
     * Render HTML
     */
    if(!(webview)){
    return (
        <>
         {download&&<div className={styles.download}>
                    <div className="flex gap-3 items-center">
                     <img src="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/65/91/05/6591057b-cd2e-9bd6-4aa7-d38b4ebe77bf/AppIcon-0-0-1x_U007emarketing-0-7-0-0-85-220.png/135x135bb.png" width={40} />
                     <div>
                        <h4 className="font-bold britanica" style={{lineHeight:1}}>EXZi App</h4>
                        <p className="opacity-70">Trade Fearlessly</p>
                     </div>
                    </div>
                    

                  
                    <NavLink
                        className={cn("button-mini", styles.button, styles.buttonAuth)}
                        activeClassName={styles.active}
                        to="/download"
                       
                    >{t(`${page}.get`)}</NavLink>
                    <div className={styles.close} onClick={()=>{showDownload(false)}}>
                    <Icon name="x-close" size="12" />
                    </div>
                    
                </div>}
             <header className={styles.header} data-light-theme="false">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <NavLink className={styles.logo} to="/" onClick={() => setVisibleNav(false)}>
                        <Image
                            className={styles.picDesktop}
                            src={"/images/logo-light.svg"}
                            srcDark="/images/logo-dark.svg"
                            alt="EXZi"
                        />
                        <Image
                            className={styles.picMobile}
                            srcDark={"/images/logo.svg"}
                            src={"/images/logo.svg"}
                            alt="EXZi"
                        />
                        <p className={styles.beta}>Beta</p>
                    </NavLink>
                    
                    <div  className={cn("ml-5 isDesktop", styles.headspllin)} ></div>
                    <nav
                        aria-label="Main"
                        data-orientation="horizontal"
                        dir="ltr"
                        className="Navigation_navigation__SaCR1"
                    >
                        <div className="Navigation_island__hx7CL">
                            <div >
                                <ul
                                    data-orientation="horizontal"
                                    className="Navigation_navbar__drXfa"
                                    dir="ltr"
                                >
                                    {!(token && sign) &&
                                        <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
                                            <nav>
                                                {navigation.map((x, index) =>
                                                    x.dropdown ? (
                                                        <DropdownHeader
                                                            className={styles.titles}
                                                            key={index}
                                                            item={x}
                                                            setValue={setVisibleNav}
                                                        />
                                                    ) : (
                                                        <NavLink
                                                            style={x.disabled ? { pointerEvents: "none", color: "gray" } : {}}
                                                            className={styles.titles}
                                                            activeClassName={styles.active}
                                                            onClick={() => setVisibleNav(false)}
                                                            to={x.url}
                                                            key={index}
                                                        >
                                                            {x.title}
                                                        </NavLink>
                                                    )
                                                )}
                                            </nav>
                                            <NavLink
                                                className={cn("button-stroke", styles.button)}
                                                activeClassName={styles.active}
                                                to="/wallet-overview"
                                                onClick={() => setVisibleNav(false)}
                                            >
                                                {t(`${page}.wallet`)}
                                            </NavLink>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="Navigation_viewportPosition__zEjBF" />
                    </nav>
                    {!(token && sign) && (
                        <>
                            <button
                                className={cn(styles.burger, { [styles.active]: mobileMenuVisible })}
                                onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
                            </button>
                            
                            {/*<Theme className={styles.theme_switcher} icon />*/}
                        </>
                    )}
                </div>

                {(!(token && sign) && mobileMenuVisible) && (
                    <div className={cn(styles.wrap, { [styles.visible]: mobileMenuVisible })}>
                        <nav>
                            {navigation.map((x, index) =>
                                x.dropdown ? (
                                    <DropdownHeader
                                        className={styles.dropdown}
                                        key={index}
                                        item={x}
                                        setValue={setMobileMenuVisible}
                                    />
                                ) : (
                                    <NavLink
                                        className={styles.titles}
                                        activeClassName={styles.active}
                                        onClick={() => setMobileMenuVisible(false)}
                                        to={x.url}
                                        key={index}
                                    >
                                        {x.title}
                                    </NavLink>
                                )
                            )}
                        </nav>
                        {(session_manager.isLoggedIn() ? (<NavLink
                            className={cn("button-stroke", styles.button)}
                            activeClassName={styles.active}
                            to="/wallet-overview"
                            onClick={() => setMobileMenuVisible(false)}
                        >
                            {t(`${page}.wallet`)}
                        </NavLink>) : authButtons())}
                    </div>
                )}
                <ul
                    data-orientation="horizontal"
                    className="Navigation_navbar__drXfa"
                    dir="ltr"
                >
                    {(!session_manager.isLoggedIn() ? (
                        <>
                            {/*<div className={cn(styles.wrap, {[styles.visible]: visibleNav})}>*/}
                            {/*    {authButtons()}*/}
                            {/*</div>*/}
                            
                            <Dropdown
                                iconDropdown='barcode'
                                iconSize='24'
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                classDropdownBody={styles.dropdownBody}
                                contentInTop={
                                <div className="text-center">
                                    <Image
                                        // style={{ marginLeft: "auto" }}
                                        src={"/images/home/qr-dark.svg"}
                                        srcDark="/images/home/qr.svg"
                                        alt=""
                                        className="px-5 py-2 "
                                    />
                                    <strong className="">Download App</strong>
                                </div>}
                            />
                            <Dropdown
                                iconDropdown='globe'
                                iconSize='24'
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                classDropdownBody={styles.dropdownBody}
                                value={languagesList[i18n.language]}
                                setValue={(v) => {
                                    localStorage.setItem('lngCode', languagesFlip[v]);
                                    i18n.changeLanguage(languagesFlip[v]);

                                    if(accountInfo){
                                        clevertap.event.push("language_changed", {
                                            userId: accountInfo.profile.id,
                                            language: v,
                                            iso: languagesFlip[v]
                                        });
                                    }
                                }}
                                options={Object.values(languagesList).filter(name => name !== null)}
                            />

                            {!isHomePage&&<Theme className={styles.theme_switcher} icon />}
                            <div  className={cn("isDesktop", styles.headspllin)} ></div>
                            <div className={styles.picDesktop}> {authButtons()}</div>
                            {/*<button*/}
                            {/*    className={cn(styles.burger, {[styles.active]: visibleNav})}*/}
                            {/*    onClick={() => setVisibleNav(!visibleNav)}>*/}
                            {/*</button>*/}

                            {/* <NavLink
                            className={cn("button-stroke", styles.button)}
                            activeClassName={styles.active}
                            to="/wallet-overview"
                            onClick={() => setMobileMenuVisible(false)}
                        >
                            {t(`${page}.wallet`)}
                        </NavLink> */}
                        </>
                    ) : (
                        <>
                            {/* <Settings /> */}
                            {/* <Notifications className={styles.notifications} /> */}
                            <Dropdown
                                iconDropdown='globe'
                                iconSize='24'
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={languagesList[i18n.language]}
                                setValue={(v) => {
                                    localStorage.setItem('lngCode', languagesFlip[v]);
                                    i18n.changeLanguage(languagesFlip[v]);
                                }}
                                options={Object.values(languagesList).filter(name => name !== null)}
                            />
                            <NavLink
                                className={cn("button-stroke button-small", styles.button, isHomePage && styles.buttonDark)}
                                activeClassName={styles.active}
                                to="/wallet-overview"
                                onClick={() => {
                                    clevertap.event.push("asset_page_clicked", {
                                        userId: accountInfo?.profile?.id
                                    })
                                }}
                            >
                                <img src="/icons/wallet.svg" />
                            </NavLink>
                            {/* {!isHomePage && <Theme className={styles.theme} icon />} */}
                            <User className={styles.user} />
                        </>
                    ))}
                </ul>
            </header>
            <div style={{ height: 55 }}></div>
        </>
     );}
   
};

export default Header;
