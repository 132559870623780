import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from 'react-hot-toast';
import "./assets/styles/app.sass";
import loaderStyles from './assets/styles/app-loader.module.sass'
import toastStyles from './assets/styles/toast.module.sass'

// ### Layouts
import PageLayout from "./layouts/PageLayout";
// import AuthLayout from "./layouts/AuthLayout";

// ### Routes lists
import AuthMiddleware from "./routes/AuthMiddleware";
import { PublicRoutesList } from "./routes/PublicRoutesList";
import { ProtectedRoutesList } from "./routes/ProtectedRoutesList";
import { PublicAuthRoutesList } from "./routes/PublicAuthRoutesList";

// ### Global Store
import { configsRequest } from "./store/actions/Config/ConfigsRequest";
import { getTickers } from "./store/actions/Ticker/GetTickers";

// ### SOCKET CONNECTION
import { socket, SocketContext } from './context/socket';
import { updateActiveOrdersData } from "./store/actions/User/UpdateActiveOrdersData";
import { updateHistoryOrdersData } from "./store/actions/User/UpdateHistoryOrdersData";
import { updateBalanceOnSocket } from "./store/actions/User/UpdateBalanceOnSocket";
import { updateActiveMarginOrdersData, updateMarginHistoryOrdersData } from "./store/actions/User/UpdateMarginOrdersData";
import { updateShortBookOnSocket } from "./store/actions/OrdersBook/UpdateShortBookOnSocket";
import { updateHistoryOrdersOnSocket } from "./store/actions/User/UpdateHistoryOrdersOnSocket";
import { updateGraphOnSocket } from "./store/actions/Graph/UpdateGraphOnSocket";
import { updateOrderCreateAnswerOnSocket } from "./store/actions/User/UpdateOrderCreateAnswerOnSocket";
import { refillCryptoUpdate } from "./store/actions/Wallet/RefillCryptoUpdate";
import { withdrawCryptoUpdate } from "./store/actions/Wallet/WithdrawCryptoUpdate";
//import { refillFiatUpdate } from "./store/actions/Wallet/RefillFiatUpdate";
//import { withdrawFiatUpdate } from "./store/actions/Wallet/WithdrawFiatUpdate";
//import fetchVerification from "./store/actions/User/FetchVerification";

import Loader from "./components/Loader";
import NotFound from "./screens/NotFound";
import RouteLoader from "./routes/RouteLoader";
import Alert from "./components/Alert/Alert";
import {updateVerificationStatus} from "./store/actions/User/UpdateVerificationStatus";
import SeoMetaDataHandle from "./SeoMetaDataHandle";
import axios from "./utils/Api";
import {updateBookDeltaOnSocket} from "./store/actions/OrdersBook/UpdateBookDeltaOnSocket";
import {updateBookSnapshotOnSocket} from "./store/actions/OrdersBook/UpdateBookSnapshotOnSocket";
import { availableLangs } from "./localization";
import session_manager from "./utils/SessionManager";
import Modal from "./components/Modal";
import fetchUserAccountInfo from "./store/actions/User/FetchUserAccountInfo";
import RemoveTrailingSlash from "./components/RemoveTrailingSlash";

/**
 * Main APP
 *
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    const dispatch = useDispatch();

    const {t, i18n} = useTranslation();

    const path = window.location.pathname;
    const search = window.location.search;

    const languageRegex = /^\/([a-z]{2}(?:_[A-Z]{2})?)\//;
    const match = path.match(languageRegex);

    useEffect(() => {
        if (match && match[1] && availableLangs.includes(match[1]) && i18n.language) {
            const language = match[1];
            let history = createBrowserHistory({ basename: `${language}` });
            
            if (language !== i18n.language) {
                localStorage.setItem('lngCode', language);
                i18n.changeLanguage(language);
                const currentRoute = path.replace('/' + language, '');
                history.push(currentRoute + search);
            }
        } else {
            let history = createBrowserHistory({ basename: 'en' });

            localStorage.setItem('lngCode', 'en');
            i18n.changeLanguage('en');
            history.push(path + search);

            window.location.reload();
        }
    }, [])

    useEffect(() => {
        if (match && match[1]) {
            const language = match[1];

            let history = createBrowserHistory({ basename: `${i18n.language}` });

            if (language !== i18n.language) {
                const currentRoute = path.replace('/' + language, '');
                history.push(currentRoute + search);
                window.location.reload();
            }
        }
    }, [i18n.language])


    function processRef() {
        const queryParams = new URLSearchParams(window.location.search);

        // access the query parameters using the get() method
        const ref = queryParams.get('ref');

        if (!ref) {
            return;
        }

        localStorage.setItem('ref', ref);
    }

    async function configForAuthedUser() {
        await axios.get('/v3/users/trackers-ids')
            .then(r => {
                window.postMessage({type: 'request_pushes', user: r.data.data });
            });
    }

    useEffect(() => {
        //console.log('App root');

        // Get Configs
        dispatch(configsRequest());

        // Get ticker and update each 2 sec
        const interval = setInterval(() => {
            dispatch(getTickers());
        }, 2000);

        processRef();

        // Set default language
        if (!localStorage.getItem('lngCode')) {
            localStorage.setItem('lngCode', process.env.REACT_APP_DEFAULT_LANG);
            i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANG)
        }

        const token = localStorage.getItem('user-token');

        if (token) {
            configForAuthedUser();
        } else {
            window.postMessage({ type: 'request_pushes' });
        }

        axios.get('/api/infoset')
            .then((response) => {
                const data = response.data;

                window.InfosetChat('boot',{
                    widget: {
                        apiKey:'Nj656gSdfL6wwEub6op6TdhiKO71YWgeLzKj3ywH'
                    },
                    visitor: {
                        id: data.data.user_id,
                        userHash: data.data.userHash,
                    },
                });
            })

        socket.on('connect', () => {
            //console.log('SOCKET CONNECT');
            socket.emit('subscribe', { type: 'history-free', event: 'free_coin_5' });
            socket.emit('subscribe', { event: 'currency_status' });
            socket.emit('subscribe', 'currency_status');

            const token = localStorage.getItem('user-token');
            if (token) {
                socket.emit('auth', {
                    data: {
                        token,
                    },
                });
            }
        });

        socket.on('message', function (msg) {
            // console.log('SOCKET MESSAGE', msg);
            for (const el in msg) {
                const type = msg[el].type;
                const data = msg[el].data;

                switch (type) {
                    // case 'history-free': {
                    //     dispatch({type: 'SET_ITEM_HISTOR', payload: data});
                    //
                    //     break;
                    // }

                    // case 'currency_status': {
                    //     dispatch(updateCurrenciesStatusOnSocket(data))
                    //
                    //     break;
                    // }

                    case 'update_config': {
                        if(data.config){
                            dispatch({
                                type: 'UPDATE_CONFIG_ON_SOCKET',
                                payload: data.config
                            });
                        }

                        break;
                    }
                    case 'short_book': {
                        dispatch(updateShortBookOnSocket(type, msg[el]));

                        break;
                    }
                    case 'book_delta': {
                        dispatch(updateBookDeltaOnSocket(type, msg[el]));

                        break;
                    }
                    case 'book_snapshot': {
                        dispatch(updateBookSnapshotOnSocket(type, msg[el]));

                        break;
                    }
                    case 'hist': {
                        dispatch(updateHistoryOrdersOnSocket(type, msg[el]));

                        break;
                    }
                    case 'graph': {
                        dispatch(updateGraphOnSocket(msg[el]));

                        break;
                    }
                    case 'user': {
                        dispatch(updateOrderCreateAnswerOnSocket(data.order));

                        switch (data.data_type) {
                            case 'order': {
                                if (data.order.status === 0 || data.order.status === 1 || data.order.status === 6) {
                                    dispatch(updateActiveOrdersData(data.order))
                                } else if (data.order.status !== -1) {
                                    dispatch(updateHistoryOrdersData(data.order))
                                }

                                const data_order = data.order;
                                const type = data_order.type === 0 ? "Buy" : "Sell"
                                const orderTypeTrades = [
                                    t(`exchange.orderForm.orderTypeNavigation.0`),
                                    t(`exchange.orderForm.orderTypeNavigation.1`),
                                    t(`exchange.orderForm.orderTypeNavigation.2`),
                                ];

                                const price = !((data_order.trade_type === 0 || data_order.trade_type === 2))
                                    ? data_order.rate_avg_done_f
                                    : data_order.rate_f

                                if (data_order.status === 2) {
                                    toast(`Your ${data_order.volume_done_f} ${data_order.pair.name} ${orderTypeTrades[data_order.type_trade]} ${type} order is filled @ ${price}`, { style: { background: '#37ABBA' } })
                                }

                                break;
                            }
                            case 'deposit': {
                                //console.log('Socket - user - deposit', data)
                                //dispatch(updateBalanceOnSocket(data.deposit));

                                break;
                            }
                            default: {
                                break;
                            }
                        }

                        break;
                    }
                    case 'balances_volume': {
                        //console.log('Socket - balances_volume', data)
                        dispatch(updateBalanceOnSocket(data));

                        break;
                    }
                    case 'margin_orders': {
                        dispatch(updateActiveMarginOrdersData(data))

                        break;
                    }
                    case 'margin_order': {
                        if (data.status === 8 || data.status === 47) {
                            dispatch(updateActiveMarginOrdersData([data]));
                        } else if (data.status === 30 || data.status === 15) {
                            dispatch(updateMarginHistoryOrdersData([data]));
                        }

                        break;
                    }
                    case 'refill': {
                        dispatch(refillCryptoUpdate(data.order));

                        break;
                    }
                    // case 'refill_fiat': {
                    //     dispatch(refillFiatUpdate(data.order));
                    //
                    //     break;
                    // }
                    case 'deposit': {
                        dispatch(refillCryptoUpdate(data.order));

                        break;
                    }
                    case 'withdraw': {
                        dispatch(withdrawCryptoUpdate(data.order));

                        break;
                    }
                    // case 'withdraw_fiat': {
                    //     dispatch(withdrawFiatUpdate(data.order));
                    //
                    //     break;
                    // }

                    case 'status_verification': {
                        // dispatch(fetchVerification());
                        dispatch(updateVerificationStatus(data));

                        break;
                    }
                    default:
                        break;
                }
            }
        });

        return () => clearTimeout(interval);
    }, [dispatch])

    const [forceChangePasswordModal, setForceChangePasswordModal] = useState(false);
    const accountInfo = useSelector(state => state.user.account_info);

    useEffect(() => {
        if (session_manager.isLoggedIn() && !accountInfo) {
            dispatch(fetchUserAccountInfo())
        }

        if (session_manager.isLoggedIn() && accountInfo && accountInfo?.security?.should_change_password && !window.location.pathname.endsWith('/change-password')) {
            setForceChangePasswordModal(true);
        }
    }, [session_manager.isLoggedIn(), accountInfo])

    const isConfigsLoaded = useSelector(({ config }) => config.isConfigsLoaded);
    const isDarkMode = document.body.classList.contains('dark-mode');
    const news_path = useSelector(state => state.config.news_path);

    useEffect(() => {
        const storedNewsPath = localStorage.getItem('news_path');

        if (isConfigsLoaded && news_path !== storedNewsPath) {
            localStorage.setItem('news_path', news_path);
        }
    }, [isConfigsLoaded, news_path])

    let allowedFastRoute = window.location.pathname === '/2fa-link-confirmation-sent' || window.location.pathname.startsWith('/link-confirmation');

    return (
        <HelmetProvider>
            <SocketContext.Provider value={socket}>
                {(isConfigsLoaded || allowedFastRoute) && (
                    <Router basename={i18n.language}>
                        <RemoveTrailingSlash />
                        <RouteLoader>
                            <Switch>
                                {ProtectedRoutesList.map((route, idx) => (
                                    <AuthMiddleware
                                        key={idx}
                                        layout={PageLayout}
                                        headerHide={true}
                                        footerHide={true}
                                        component={route.component}
                                        path={route.path}
                                        isAuthProtected={true}
                                        exact
                                    />
                                ))}

                                {PublicAuthRoutesList.map((route, idx) => (
                                    <AuthMiddleware
                                        key={idx}
                                        layout={PageLayout}
                                        footerHide={false}
                                        headerHide={false}
                                        component={route.component}
                                        path={route.path}
                                        isAuthProtected={false}
                                        authCheck={true}
                                        exact
                                    />
                                ))}

                                {PublicRoutesList.map((route, idx) => (
                                    <AuthMiddleware
                                        key={idx}
                                        layout={PageLayout}
                                        headerHide={true}
                                        footerHide={true}
                                        component={route.component}
                                        path={route.path}
                                        isAuthProtected={false}
                                        exact
                                    />
                                ))}

                                <AuthMiddleware
                                    layout={PageLayout}
                                    headerHide={true}
                                    footerHide={true}
                                    component={NotFound}
                                    path={''}
                                    isAuthProtected={false}
                                    exact
                                />
                            </Switch>
                        </RouteLoader>

                        <Toaster
                            toastOptions={{
                                className: toastStyles.toast_main,
                                duration: 10000,
                                position: 'top-center',
                                success: {
                                    duration: 10000,
                                    className: toastStyles.toast_success,
                                    style: {
                                        background: '#13B195',
                                        color: '#FCFCFD'
                                    }
                                },
                                error: {
                                    duration: 10000,
                                    className: toastStyles.toast_error,
                                    style: {
                                        background: '#FE8375',
                                        color: '#FCFCFD'
                                    }
                                },
                            }}
                        />

                        <Modal
                            visible={forceChangePasswordModal}
                            onClose={() => setForceChangePasswordModal(false)}
                            title={'Password Change Required'}
                            disableGoBackButton
                            disableOutsideClose
                        >
                            <p className="my-3">For your security, we require passwords to be updated every 3 months.</p>

                            <p className="my-3">Your current password has expired. Please take a moment to create a new password.</p>

                            <p className="my-3">Remember to choose a strong, unique password that:</p>
                            <ul className="list-disc list-inside">
                                <li>Is at least 8 characters long</li>
                                <li>Contains a mix of uppercase and lowercase letters</li>
                                <li>Includes numbers and special characters</li>
                                <li>Is not used for any other accounts</li>
                            </ul>

                            <p className="my-3">Click 'Change Password' below to proceed.</p>

                            <div className="w-full flex justify-center">
                                <Link onClick={() => setForceChangePasswordModal(false)} className="button mt-5 cursor-pointer" to='/change-password'>
                                    Change Password
                                </Link>
                            </div>
                        </Modal>
                    </Router>
                ) || (
                        <div className={isDarkMode ? loaderStyles.loader_dark : loaderStyles.loader_light}>
                            <Loader />
                        </div>
                    )}
            </SocketContext.Provider>
        </HelmetProvider>
    );
}

export default App;
