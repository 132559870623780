import axios from "../../../utils/Api";
import session_manager from "../../../utils/SessionManager";
import { changeCurrentPair } from "../Ticker/ChangeCurrentPair";

// async
export function configsRequest() {
    return (dispatch, getState) => {
        //return new Promise((resolve) => {
            let ticker = getState().ticker;
            let config = getState().config;

            // console.log('configsRequest before axios')
            axios.get('/api/default/config')
                .then((response) => {
                    // Check response valid status
                    if (response.status !== 200) {
                        //resolve('Config response is not valid');
                        return false;
                    }

                    // Set demo flag if user is not logged in
                    if (!session_manager.isLoggedIn()) {
                        session_manager.setDemoFlag('1');
                    }

                    const data = response.data.data;
                    //console.log('configsRequest response', data)

                    dispatch({type: 'ADD_CONFIGS', payload: data})

                    dispatch({type: 'SET_FAVORITES', payload: data.favorite_pairs})

                    // Set languages configs
                    dispatch({type: 'SET_LANGUAGE', payload: data.language})
                    dispatch({type: 'SET_LANGUAGES', payload: data.languages})

                    dispatch({type: 'USER_VERIFIED', payload: !!data.status_verification})
                    dispatch({type: 'ADD_ICONS', payload: data.currency_icons})

                    if (!Number(ticker.current_id)) {
                        dispatch(changeCurrentPair(config.default_pair_id));
                    }

                    // console.log('currency_data', data.currency_data);
                    for (const {StatusFromCoinService} of Object.values(data.currency_data)) {
                        if (StatusFromCoinService) {
                            for (const status of StatusFromCoinService) {
                                dispatch({
                                    type: 'SET_CURRENCY_STATUS',
                                    payload: status
                                })
                            }
                        }
                    }
                    // dispatch({
                    //     type: 'UPDATE_CURRENCY_STATUS',
                    //     payload: ''
                    // })

                    //resolve('ok');
                })
                .catch((err) => {
                    dispatch({
                        type: 'GET_CONFIGS_ERROR',
                        payload: err
                    })
                });
        //});
    }
}