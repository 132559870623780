import React, { Suspense, useEffect } from "react";

const RouteLoader = ({ children }) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

export default RouteLoader;

function Loader() {
  useEffect(() => {
    document.querySelector("#loader").classList.remove("loader-hide");
    return () => document.querySelector("#loader").classList.add("loader-hide");
  }, []);
}
