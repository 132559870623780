import {ordersSortArray} from "../utils/OrdersSortArray";
import {ordersMergeArrays} from "../utils/OrdersMergeArrays";
import TraderPrepare from "../../utils/TraderPrepare";

const initialState = {
    book_lines: 1000,
    book_depth: 2000,
    // isConnected: false,
    buyOrders: [],
    sellOrders: [],
    graphBuyOrders: [],
    graphSellOrders: [],
    graphBuyOrdersReady: false,
    graphSellOrdersReady: false,
    socketBuyOrders: [],
    socketSellOrders: [],
    volumes: {
        total_both: 0,
        total_sell: 0,
        total_buy: 0
    },
    book_show_types: [
        'cumulative_t',
        'separately_t',
        'order_own'
    ],
    buyOrders_show: [],
    sellOrders_show: [],
    book_resp_come: false,
    initial_orders_come: false,
    order_create_rate: null,
    order_create_amount: undefined,
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'UPDATE_ORDERS': {
            // console.log('UPDATE_ORDERS data', data);

            // if (!rootGetters.isSubscribedOn(data.room)) {
            //     return;
            // }

            let type = 'buy';
            if (data.data.hasOwnProperty('sell')) {
                type = 'sell';
            }

            let curr_orders = [];
            let order = {};

            if (type === 'buy') {
                curr_orders = state.buyOrders;
                order = data.data.buy;
            } else {
                curr_orders = state.sellOrders;
                order = data.data.sell;
            }

            const orders_count = curr_orders.length;

            for (const key in order) {
                let found = false;

                for (let i = 0; i < orders_count; i += 1) {
                    if (order[key].rate === curr_orders[i].rate) {
                        found = true;

                        if (order[key].volume) {
                            curr_orders[i] = order[key];
                        } else {
                            curr_orders.splice(i, 1);
                        }

                        break;
                    }
                }

                if (!found) {
                    if (order[key].volume) {
                        if (orders_count !== 0 || state.initial_orders_come) {
                            curr_orders.push(order[key]);
                        } else {
                            if (type === 'sell') {
                                state.socketSellOrders.push(order[key]);
                            } else {
                                state.socketBuyOrders.push(order[key]);
                            }
                        }
                    }
                }
            }

            // Sorting orders
            const newState = ordersSortArray(curr_orders, state, type);
            // console.log('UPDATE_ORDERS', newState.sellOrders_show, newState.buyOrders_show);

            return {...state, ...newState};
        }

        case 'UPDATE_ORDERS_DELTA': {
            let newState = { ...state };

            for (const type in data) {
                const newOrders = data[type];
                const currentOrders = state[type + 'Orders'];

                const currentOrdersCount = currentOrders.length;

                // Update or add new orders
                for (const key in newOrders) {
                    const newOrder = newOrders[key];

                    // Find index of the order with the matching rate
                    const index = currentOrders.findIndex(order => Number(order.rate) === Number(newOrder.rate));

                    if (index !== -1) {
                        // Order with matching rate found
                        if (newOrder.volume !== 0) {
                            // Update the existing order
                            currentOrders[index] = newOrder;
                        } else {
                            // Remove the order if its volume is zero
                            currentOrders.splice(index, 1);
                        }
                    } else {
                        // Order not found, consider adding a new one
                        if (newOrder.volume !== 0) {
                            if (currentOrdersCount !== 0 || state.initial_orders_come) {
                                // Add a new order to current orders
                                currentOrders.push(newOrder);
                            } else {
                                // If there are no existing orders and initial_orders_come is false,
                                // add the order to socketSellOrders or socketBuyOrders
                                if (type === 'sell') {
                                    state.socketSellOrders.push(newOrder);
                                } else {
                                    state.socketBuyOrders.push(newOrder);
                                }
                            }
                        }
                    }
                }

                // Sort the orders
                if (type === 'sell') {
                    currentOrders.sort((a, b) => Number(a.rate) - Number(b.rate));
                    newState.sellOrders = currentOrders;
                } else {
                    currentOrders.sort((a, b) => Number(b.rate) - Number(a.rate));
                    newState.buyOrders = currentOrders;
                }

                // Prepare the order book data
                const ordersData = TraderPrepare.prepareBookOrders(currentOrders, newState.book_lines);

                if (type === 'sell') {
                    newState.volumes.total_sell = ordersData.total;
                    newState.volumes.total_both = ordersData.total + newState.volumes.total_buy;
                    newState.sellOrders_show = ordersData.orders;
                } else {
                    newState.volumes.total_buy = ordersData.total;
                    newState.buyOrders_show = ordersData.orders;
                }
            }

            return { ...state, ...newState };
        }

        case 'DEL_MAIN_DATA_QE': {
            return {...state, qe_main_data: null}
        }
        case 'RESET_BUY_ORDERS_LIST': {
            state.buyOrders = [];
            state.buyOrders_show = [];
            state.volumes = {};

            return state;
        }
        case 'RESET_SELL_ORDERS_LIST': {
            state.sellOrders = [];
            state.sellOrders_show = [];
            state.volumes = {};

            return state;
        }
        case 'BOOK_ORDERS_READY_FLAG': {
            return {...state, book_resp_come: data}
        }
        case 'SET_BUY_GRAPH_ORDERS': {
            return {...state, graphBuyOrders: data}
        }
        case 'SET_SELL_GRAPH_ORDERS': {
            return {...state, graphSellOrders: data}
        }
        case 'SET_BUY_GRAPH_ORDERS_STATE': {
            return {...state, graphBuyOrdersReady: data}
        }
        case 'SET_SELL_GRAPH_ORDERS_STATE': {
            return {...state, graphSellOrdersReady: data}
        }
        case 'SET_ORDER_RATE': {
            return {...state, order_create_rate: data}
        }
        case 'SET_ORDER_AMOUNT': {
            return {...state, order_create_amount: data}
        }
        case 'GOT_INITIAL_ORDERS_LIST': {
            state.initial_orders_come = true;
            state.book_resp_come = true;

            // merge orders
            if (data.buy) {
                let orders = data.buy;

                if (state.socketBuyOrders.length > 0) {
                    orders = ordersMergeArrays(state.socketBuyOrders, orders);
                }

                const newState = ordersSortArray(orders, state, 'buy');
                //console.log('UPDATE_ORDERS buy', newState);

                state = {...state, ...newState};
            } else {
                state.buyOrders = [];
                state.buyOrders_show = [];
                state.volumes = {};
            }

            if (data.sell) {
                let orders = data.sell;

                if (state.socketSellOrders.length > 0) {
                    orders = ordersMergeArrays(state.socketSellOrders, orders);
                }

                const newState = ordersSortArray(orders, state, 'sell');
                //console.log('UPDATE_ORDERS sell', newState);

                state = {...state, ...newState};
            } else {
                state.sellOrders = [];
                state.sellOrders_show = [];
                state.volumes = {};
            }

            return state;
        }
        default:
            return state;
    }
}