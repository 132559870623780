import React from "react";

import cn from "classnames";
import styles from "./TextInput.module.sass";

import PhoneInput from "react-phone-input-2";
import NumberValidatedInput from "../NumberValidatedInput";
import Icon from "../../Icon";

const TextInput = React.forwardRef(({
    numberValidated = false,
    className,
    classLabel,
    classInput,
    error,
    label, empty,
    view,
    icon,
    iconSize,
    classIcon,
    note,
    onClickView,
    type,
    disabled,
    button,
    buttonOnClick,
    buttonClassName,
    ...props 
}, ref) => {

    /**
     * Render template
     */
    return (
        <div
            className={cn(
                styles.field,
                { [styles.empty]: empty },
                { [styles.view]: view },
                { [styles.icon]: icon },
                { [styles.error]: error },
                className
            )}
        >
            {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
            <div className={styles.wrap}>
                {(numberValidated) &&
                    <NumberValidatedInput
                        className={cn(classInput, styles.input, disabled ? styles.disabled : null)}
                        disabled={disabled}
                        inputMode="numeric"
                        ref={ref}
                        {...props}
                    />}
                {((!numberValidated) && (type !== 'phone') && (type !== 'code')) &&
                    <input
                        className={cn(classInput, styles.input, disabled ? styles.disabled : null)}
                        type={type}
                        disabled={disabled}
                        ref={ref}
                        {...props}
                    />}
                {(type === 'phone') &&
                    <PhoneInput
                        inputClass={cn(styles.input)}
                        specialLabel=''
                        type={type}
                        disabled={disabled}
                        ref={ref}
                        {...props}
                    />}
                {(type === 'code') &&
                    <NumberValidatedInput
                        className={cn(classInput, styles.input, disabled ? styles.disabled : null)}
                        type={type}
                        disabled={disabled}
                        inputMode="numeric"
                        ref={ref}
                        {...props}
                    />}
                {view && (
                    <button type="button" className={styles.toggle} onClick={onClickView}>
                        <Icon name="eye" size="24" />
                    </button>
                )}
                {icon && (
                    <button type="button" className={cn(styles.preview, classIcon)}>
                        <Icon name={icon} size={iconSize || "24"} />
                    </button>
                )}
                {button && (
                    <button type={cn("button", styles.custom_button)} onClick={buttonOnClick} className={cn(styles.preview, buttonClassName)}>
                        {button}
                    </button>
                )}
                {note && <div className={styles.note}>{note}</div>}
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
});

export default TextInput;
