import i18n from "../../localization/index";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    timer_session: null,
    init_pairs: {},
    time_pasw_block: null,
    all_pairs: {}, // {id:1, main:"", second:"",}

    all_pairs_flip: {
        "BTCUSDT": 1041,
        "ETHUSDT": 1051,
        "LTCUSDT": 1061,
        "DOGEUSDT": 1101,
        "OMGUSDT": 1131,
        "BATUSDT": 1151,
        "ZRXUSDT": 1161,
        "ZILUSDT": 1171,
        "LINKUSDT": 1191,
        "MANAUSDT": 1201,
        "LRCUSDT": 1291,
        "ADAUSDT": 2471,
        "BNBUSDT": 2621,
        "WAVESUSDT": 3281,
        "MATICUSDT": 3421,
        "FTMUSDT": 3441,
        "ETCUSDT": 3771,
        "ENJUSDT": 4001,
        "TRXUSDT": 4061,
        "BTTUSDT": 4211,
        "SXPUSDT": 5531,
        "KNCUSDT": 5781,
        "BNTUSDT": 5921,
        "COMPUSDT": 6051,
        "BALUSDT": 6191,
        "YFIUSDT": 6391,
        "ATOMUSDT": 6431,
        "CRVUSDT": 6631,
        "DOTUSDT": 6671,
        "UMAUSDT": 6791,
        "SANDUSDT": 7031,
        "AAVEUSDT": 7081,
        "GRTUSDT": 7401,
        "YFIIUSDT": 7421,
        "SUSHIUSDT": 7461,
        "UNIUSDT": 7481,
        "1INCHUSDT": 7561,
        "AXSUSDT": 7601,
        "BUSDUSDT": 7661,
        "EGLDUSDT": 8021,
        "CAKEUSDT": 8091,
        "CHZUSDT": 8211,
        "ANKRUSDT": 8614,
        "APEUSDT": 8624,
        "AUDIOUSDT": 8634,
        "CVXUSDT": 8644,
        "ENSUSDT": 8654,
        "GALAUSDT": 8664,
        "IMXUSDT": 8674,
        "JSTUSDT": 8684,
        "LDOUSDT": 8694,
        "LPTUSDT": 8704,
        "OGNUSDT": 8714,
        "PAXGUSDT": 8724,
        "PLAUSDT": 8734,
        "QNTUSDT": 8744,
        "SKLUSDT": 8754,
        "SHIBUSDT": 8764,
        "WOOUSDT": 8774,
        "NEARUSDT": 8794
    },
    all_currencies: undefined,
    all_currencies_flip: undefined,
    active_currencies: [],
    fiat_currencies: [],
    withdraw_minimal: {}, // {ISO:0.5},
    stock_min_amount: {}, // {Pair:0.01},
    stock_max_amount: {}, // {Pair:0.01},

    // available_languages: Locales.available,
    short_months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ],

    portfolio_diagram_colors: [
        '#00aa00',
        '#0000aa',
        '#aa0000',
        '#aaaa00',
        '#00aaaa',
        '#aa00aa',
        '#80ff80',
        '#8080ff',
        '#ff8080',
        '#ffff80',
        '#80ffff',
        '#ff80ff',
        '#008000',
        '#000080',
        '#800000',
        '#808000',
        '#008080',
        '#800080',
        '#80ff80',
        '#8080ff',
        '#ff8080',
        '#ffff80',
        '#80ffff',
        '#ff80ff',
    ],
    has_tag: [],

    // *      Network Types
    //  const TYPE_NETWORK_MAIN = 0;
    //  const TYPE_NETWORK_ETH = 1;
    //  const TYPE_NETWORK_TRX = 2;
    //  const TYPE_NETWORK_BNB = 3;
    //  const TYPE_NETWORK_BSC = 4;

    network_type_names: {
        0: 'Mainnet',
        1: 'ERC 20',
        2: 'TRC 20',
        3: 'BNB',
        4: 'BEP 20',
    },

    network_type_names_flip: {
        'Mainnet': 0,
        'ERC 20': 1,
        'TRC 20': 2,
        'BNB': 3,
        'BEP 20': 4,
    },

    orderTypes: {
        0: i18n.t(`orderTypes.0`),     // type_trade = 0 Limit
        1: i18n.t(`orderTypes.1`),     // type_trade = 1 Market
        2: i18n.t(`orderTypes.2`),     // type_trade = 2 Stop-Limit
    },

    currency_pairs_margin_leverage: {},

    // iso_pictures: iso_images_names,
    // iso_pictures_color: iso_images_names_color,

    all_icon: null,

    //flags_pictures,

    configs_ready: {
        all_pairs: false,
        all_currencies: false,
        time_zones: false,
        user_pairs: false,
        trade_commission: false,
    },
    current_lang: null,
    user_pairs: {},
    time_zones: {},
    user_t_zone: undefined,
    min_btc_amount_withdraw: 0,

    trade_commission: null,
    refill_commission: null,
    // withdraw_fees: [], // {name:'USD', fee:1}
    nice_ccy_names: {},
    all_coins_info: [],
    currency_descriptions: [],

    // socials: [
    //     ...innerConfig.socials
    // ],

    vertical_socials: {
        twitter: true,
        telegram: true,
        facebook: true,
        medium: true,
    },
    socials_classes: {
        twitter: 'fa-twitter',
        telegram: 'fa-telegram-plane',
        facebook: 'fa-facebook-f',
        instagram: 'fa-instagram',
        reddit: 'fa-reddit',
        medium: 'fa-medium',
        vkontakte: 'fa-vk',
        linkedIn: 'fa-linkedin-in',
        GitHub: 'fa-github',
        etherscan: 'fa-ether',
    },
    ieo_documents_classes: {
        youtube: 'yt_link',
        pdf: 'pdf_file',
        else: 'excel_file',
    },
    ieo_documents_item_classes: {
        pdf: 'pdf',
        youtube: 'yt',
        else: 'xcl',
    },
    ieo_list: null,
    max_limit_btc: 0,
    curr_withdraw: null,
    curr_deposit: null,

    currency_decimals: {},
    currency_data: {},
    pairs_info_orders_book: {},
    favorites_pairs: [],
    type_captcha: null,

    slider: [],
    default_pair_id: null,
    default_pair: null,
    currency_voting: null,
    settings_withdraw: [],
    withdraw_steps_user_require: [],
    pairs_limit_rate: null,
    refill: [],
    currency_status: {},

    verification_settings: undefined,

    block_is_active: {
        margin_active: false,
        news_active: false,
        private_wallets_active: false,
    },

    vip_data: {
        vip_params: null,
        vip_ranges: null,
    },

    project_name: '',
    project_host: '',

    news_path: null,

    isConfigsLoaded: false,

    topTickers: [],

    languages: {},
    languages_flip: {}
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'ADD_CONFIGS': {
            //console.log('ADD_CONFIGS', data);

            let all_currencies_flip = {};

            for (const key in data.all_currency) {
                all_currencies_flip = {
                    ...all_currencies_flip,
                    [data.all_currency[key]]: key
                };
            }

            if (data.session) {
                localStorage.setItem('session', data.session);
                state.timer_session = data.session;
            }

            state.settings_withdraw = data.settings_withdraw ? data.settings_withdraw.require : null;
            state.withdraw_steps_user_require = data.settings_withdraw.user_require ? data.settings_withdraw.user_require : null;

            state.curr_withdraw = data.currency_withdraw;
            state.curr_deposit = data.currency_deposit;

            state.pairs_info_orders_book = {
                currency_decimals: data.currency_decimals,
                pairs_info: data.pairs
            };
            state.currency_decimals = data.currency_decimals;
            state.currency_data = data.currency_data;
            state.nice_ccy_names = data.nice_ccy_names ?? null;
            state.init_pairs = data.all_pairs;

            state.all_currencies = data.all_currency;
            state.all_currencies_flip = all_currencies_flip;

            state.fiat_currencies = data.fiat_currencies;
            state.user_pairs = data.user_pairs;
            state.time_zones = data.time_zones;
            state.min_btc_amount_withdraw = data.min_btc_amount_withdraw ? data.min_btc_amount_withdraw : 0;
            state.time_pasw_block = data.time_password_block ? data.time_password_block : null;

            state.all_pairs = data.pairs;
            state.all_pairs_flip = data.all_pairs_flip;
            state.withdraw_minimal = data.withdraw;

            state.stock_min_amount = data.trade_min || 0;
            state.stock_max_amount = data.trade_max || 0;

            state.currency_pairs_margin_leverage = data.currency_pairs_margin_leverage;

            state.trade_commission = data.trade_commission;

            state.refill_commission = data.commission_refill;

            state.commission = data.commission;
            state.has_tag = data.has_tag;
            state.configs_ready.trade_commission = !!data.trade_commission;
            state.configs_ready.user_pairs = true;
            state.configs_ready.all_currencies = true;
            state.configs_ready.time_zones = true;
            state.configs_ready.all_pairs = true;
            state.type_captcha = data.d_c;

            state.slider = data.slider;

            state.default_pair = data.default_pair.name;
            state.default_pair_id = data.all_pairs_flip[data.default_pair.name];

            state.time_login_block = data.time_login_block;
            state.currency_voting = data.currency_voting;
            state.pairs_limit_rate = data.pair_limit_rate;
            state.refill = data.refill;
            state.block_is_active = data.block_is_active || {};

            state.project_name = data.project_name;
            state.project_host = data.project_host;
            state.all_icon = data.currency_icons;

            state.banned_countries = data.banned_countries || [];
            state.settings_withdraw = data.settings_withdraw;

            state.verification_settings = data.verification_settings;

            state.vip_data = {
                vip_params: data.vip_params,
                vip_ranges: data.vip_ranges,
            };

            state.news_path = data.news_path;

            state.isConfigsLoaded = true;

            return { ...state };
        }
        case 'ADD_ICONS': {
            return { ...state, all_icon: data }
        }
        case 'UPDATE_CONFIG_ON_SOCKET': {
            return {
                ...state,
                all_pairs: data.pairs,
                currency_data: data.currency_data
            }
        }
        case 'SET_CURRENCY_STATUS': {

            // data.currency_id in state.currency_status || (state.currency_status[data.currency_id] = {});

            // state.currency_status[data.currency_id][data.network_type] = {
            //     data.is_deposit_active,
            //     data.is_withdrawals_active,
            // }

            return state;
        }
        case 'UPDATE_CURRENCY_STATUS': {
            return { ...state, currency_status: { ...state.currency_status } }
        }
        case 'FETCH_ACTIVE_CURRENCIES': {
            return { ...state, active_currencies: data.data }
        }
        case 'SET_LANGUAGE': {
            let resData = data;

            if (!resData || typeof resData === 'object') {
                resData = 'en';
            }

            if (resData.length !== 2) {
                resData = 'en';
            }

            state.current_lang = resData.toLowerCase();
            // localStorage.setItem('locale', resData.toLowerCase());

            return state;
        }
        case 'SET_LANGUAGES': {
            // Do flip object
            let flipped = {};
            for (let key in data) {
                flipped[data[key]] = key;
            }

            // Return data
            return {
                ...state,
                languages: data,
                languages_flip: flipped
            };
        }
        case 'SET_SOCKETS_STATE': {
            return { ...state, socket_connected: data }
        }
        case 'SET_USER_TIMEZONE': {
            return { ...state, user_t_zone: data }
        }
        case 'SET_PAYMENT_SYSTEMS': {
            return { ...state, payment_systems: data.payments }
        }
        case 'SET_STOCK_COINS_INFO': {
            return { ...state, all_coins_info: data }
        }
        case 'RESET_PAYMENT_SYSTEMS': {
            return { ...state, payment_systems: [] }
        }
        case 'SET_COUNTRIES': {
            return { ...state, payment_countries: data }
        }
        case 'FETCH_DEFAULT_COUNTRIES': {
            return { ...state, default_countries: data.data.list };
        }
        case 'SET_CURRENCY_DESCRIPTIONS': {
            return { ...state, currency_descriptions: data }
        }
        case 'SET_LIMIT_BTC': {
            return { ...state, max_limit_btc: data }
        }
        case 'SET_FAVORITES': {
            if (data === undefined || data === null) {
                state.favorites_pairs = [];
            } else {
                state.favorites_pairs = data;
            }

            return state;
        }
        case 'FETCH_IEO': {
            return {
                ...state,
                ieo_list: data.data
            }
        }
        case 'FETCH_IEO_VIEW': {
            return {
                ...state,
                ieo_project: data.data
            }
        }
        case 'SET_TOP_TICKERS': {
            return {
                ...state,
                topTickers: data ?? []
            }
        }
        default:
            return state;
    }
}
