import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation, withRouter} from "react-router-dom";
import {clearAllBodyScrollLocks} from "body-scroll-lock";
import styles from "./PageLayout.module.sass";

// ### Layout sections
import Header from "../Header";
import Footer from "../Footer";
import Alert from "../../components/Alert/Alert";

/**
 *
 * @param headerHide
 * @param children
 * @param footerHide
 * @param headerWide
 * @returns {JSX.Element}
 * @constructor
 */
const PageLayout = ({children, headerHide, footerHide, headerWide}) => {
    const {pathname} = useLocation();

    const alert = useSelector(state => state.account.alert);

    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
    }, [pathname]);

    return (
        <div className={styles.page}>
            {headerHide && <Header headerWide={headerWide} />}

            {alert && <Alert />}

            <div className={styles.inner}>
                {children}
            </div>

            {footerHide && <Footer />}
        </div>
    );
};

export default withRouter(PageLayout);
