import axios from "../../../utils/Api";

export function getContentDetail(slug, language = 'en') {
    return (dispatch) => {
        dispatch(setContentDetailIsLoading(true))
        return new Promise((resolve) => {
            // console.log('getContentList', category, language);
            axios.post('/api/news/view', {
                "url": slug,
                "lang": language,
            })
                .then((resp) => {
                    if (resp.status) {
                        resolve('success');

                        dispatch({
                            type: 'LOAD_CONTENT_DETAIL',
                            payload: resp.data.data
                        });
                        dispatch({
                            type: 'SET_IS_CONTENT_DETAIL_LOADING',
                            payload: false
                        });
                    } else {
                        resolve('error');
                    }
                })
                .catch((err) => {
                    resolve('error');
                });
        });
    }
}

export function setContentDetailIsLoading(payload = true) {
    return (dispatch) => {
        dispatch({
            type: 'SET_IS_CONTENT_DETAIL_LOADING',
            payload
        });
    }
}
